import React from 'react';

interface MapComponentProps {
    latitude: string;
    longitude: string;
}

const MapComponent: React.FC<MapComponentProps> = ({ latitude, longitude }) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&zoom=18&maptype=roadmap&q=${latitude},${longitude}`;

    return (
        <div className="mb-3" style={{ width: '100%', height: '100%' }}>
            <iframe
                title="Map"
                width="100%"
                height="100%"
                className="shadow"
                src={mapUrl}
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default MapComponent;
