
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchDataWithParams } from '../services/ApiService';
import { WORKPLAN_LIST_URL } from '../util/constants';
import { getApprovalStatus, getApprovalStatusColor, getRoleColor, isDispCreateWorkPlanButton, isPromoter, isSupervisor, isUnauthorizedRequest } from '../util/utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { WorkPlanProps } from '../types/types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import moment from 'moment';
import Preloader from '../components/Preloader';
import CreateButton from '../components/CreateButton';
import ReactDatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

const WorkPlanList: React.FC = () => {
    const { user, token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [myWorkPlans, setMyWorkPlans] = useState<WorkPlanProps[]>([]);
    const [othersWorkPlans, setOthersWorkPlans] = useState<WorkPlanProps[]>([]);

    const [searchBy, setSearchBy] = useState<string>('');
    const [filterYearMonth, setFilterYearMonth] = useState<Date | null>(new Date());
    const [filterEntriesPerPage, setFilterEntriesPerPage] = useState<string>('10');

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line
    }, []);

    // Search button click event
    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(WORKPLAN_LIST_URL, token, {
      
            });

            const responseData: any = response.data.data;
            setMyWorkPlans(responseData.myWorkPlans);
            setOthersWorkPlans(responseData.othersWorkPlans);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching workplans', error);
            if (isUnauthorizedRequest(error)) {
                // Redirect to the login screen
                navigate('/login', { replace: true });
            }
        }
    }

    return (
        <>
            <div className="content">
                <div className="content-main">
                    <div className="content-wrapper">
                        <div className="title-container border-bottom">
                            <div className="title">
                                <h3>Workplan</h3>
                            </div>
                        </div>
                        <div className="searchFilterContainer">
                            <div className="input-group input-group-merge merge-right searchGroup">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchBy}
                                    onChange={(e) => setSearchBy(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary searchBtn"
                                    onClick={handleSearch}
                                >
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <button type="button" className="btn btn-filter btn-light" data-bs-toggle="offcanvas" data-bs-target="#filterby">
                                <i className="ri-filter-3-line"></i>
                            </button>
                        </div>
                        <div className="content-body mb-5">
                            <div className="container">
                                <div className="row">
                                    {isSupervisor(user?.role_id ?? 0) ? (
                                        <Tabs className="Tabs" >
                                            {/* <TabList>
                                                <Tab>My List</Tab>
                                                <Tab>Promoter's List</Tab>
                                            </TabList> */}
                                            <TabPanel>
                                                <ListComponent workPlans={myWorkPlans} />
                                            </TabPanel>
                                            <TabPanel>
                                                <ListComponent workPlans={othersWorkPlans} />
                                            </TabPanel>
                                        </Tabs>
                                    ) : isPromoter(user?.role_id ?? 0) ? (
                                        <ListComponent workPlans={myWorkPlans} />
                                    ) : (
                                        <ListComponent workPlans={othersWorkPlans} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {isDispCreateWorkPlanButton(user?.role_id ?? 0) && <CreateButton buttonName='Create Workplan' navPath='/workplan/reg' />}

            <div className="offcanvas offcanvas-bottom" tabIndex={-1} id="filterby" aria-labelledby="FilterLabel">
                <div className="dragger"></div>
                <div>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="FilterLabel">Filters</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>
                <div className="offcanvas-body">
                    <div className="filters">
                        <div className="form-select-group d-flex justify-content-between">
                            <label>
                                Select Month
                            </label>
                            <ReactDatePicker
                                selected={filterYearMonth}
                                onChange={(date) => setFilterYearMonth(date)}
                                dateFormat="MMMM, yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                showIcon
                                icon='ri-calendar-line'
                                className='form-control'
                            />
                        </div>
                        <div className="form-select-group">
                            <label className="d-flex">
                                Show Entries
                                <select
                                    className="form-select"
                                    aria-label="Status"
                                    value={filterEntriesPerPage}
                                    onChange={(e) => setFilterEntriesPerPage(e.target.value)}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-footer">
                    <div className="d-flex justify-content-between gap-4">
                        <button className="btn btn-tertiary btn-block" data-bs-dismiss="offcanvas">Cancel</button>
                        <button className="btn btn-primary btn-block" data-bs-dismiss="offcanvas" onClick={handleSearch}>Apply</button>
                    </div>
                </div>
            </div >

            <Preloader isLoading={isLoading} />
        </>
    );
};

export default WorkPlanList;

/**
 * List Component
 */
const ListComponent: React.FC<{ workPlans: WorkPlanProps[] }> = ({ workPlans }) => {
    return (
        <>
            {workPlans.map(workPlanMap => (
                <div key={workPlanMap.workplan_id} className="col-lg-4 col-sm-6 list-containers">
                    <div className="list-grid">
                        <div className="users">
                            <div className="user-header">
                                <div className="avatar-sec flex-shrink-1">
                                    <span className="user-name" title={workPlanMap.user_id}>{workPlanMap.user_id}</span>
                                </div>
                                <div className="avatar-sec flex-shrink-1">
                                    <span className="user-name" title={workPlanMap.user_name}>{workPlanMap.user_name}</span>
                                </div>
                                <div className="item-actions">
                                    <div className="user-designation">
                                        <span className={getApprovalStatusColor(workPlanMap.approval_status)}>{getApprovalStatus(workPlanMap.approval_status)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="user-body">
                                <ul className="user-info">
                                    <li>
                                        <div className="icon-sec"><span><i className="ri-calendar-schedule-line"></i></span></div>
                                        <div className="icon-detail"><span>{workPlanMap.start_date}~{workPlanMap.end_date}</span></div>
                                    </li>
                                    <li>
                                        <div className="icon-sec"><span><i className="ri-pass-valid-line"></i></span></div>
                                        <div className="icon-detail"><span>{workPlanMap.approver_name} <span className={getRoleColor(workPlanMap.approver_role)}>{workPlanMap.approver_role}</span></span></div>
                                    </li>
                                    {workPlanMap.assigned_by &&
                                        <li>
                                            <div className="icon-sec"><span><i className="ri-user-shared-2-line"></i></span></div>
                                            <div className="icon-detail"><span>{workPlanMap.assigned_by_name}</span></div>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="grid-actions">
                                <NavLink to={`/workPlan/detail/${workPlanMap.workplan_id}`} className="dt-link">View Details <i className="ri-arrow-right-s-line"></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
