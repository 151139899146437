
import React, { useEffect, useState, MouseEvent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, postData } from '../services/ApiService';
import { GET_CLAIM_DETAIL_URL, GET_CLAIM_STATUS_UPDATE_URL } from '../util/constants';
import { SHOP_DETAIL_URL } from '../util/constants';
import Preloader from '../components/Preloader';
import { useNavigate, useParams } from 'react-router-dom';
import { ShopDetailProps } from '../types/types';
import { formatMoneyWithCurrencySymbol, getAllowanceStatusColor, getAllowanceStatusDetail, getRoleColor, isUnauthorizedRequest } from '../util/utils';

const ShopDetail: React.FC = () => {
    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { shop_id } = useParams<{ shop_id: string }>(); // Accessing the claim_id from route parameters
    const [shop, setShop] = useState<ShopDetailProps>();    

    

    useEffect(() => {
        const fetchClaim = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(SHOP_DETAIL_URL.replace('{shop_id}', shop_id ?? ''), token);

                const responseData: any = response.data;
                console.log('sdf',responseData);

                setShop(responseData.shop);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching claim', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchClaim();
        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };

  

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Shop Details</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 mt-3">
                                        <div className="info-sec">
                                            <div className="form-details">
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-information-fill mr-2"></i>
                                                            </div>
                                                            <h5>shop information</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Shop Id</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{shop?.shop_id}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Shop Name</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{shop?.shop_name}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Gst No'</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{shop?.gst_no}</label>
                                                            </div>
                                                        </div>
                                                  
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Mobile</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{shop?.mobile}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Address</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{shop?.address}</label>
                                                            </div>
                                                        </div>
                                    
                                                    
                                                    </div>
                                                </div>
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-attachment-line"></i>
                                                            </div>
                                                            <h5>Shop Attachment</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Preloader isLoading={isLoading} />
        </>
    );
};
export default ShopDetail;
