
import React, { useEffect, useState, MouseEvent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, postData } from '../services/ApiService';
import { GET_CLAIM_DETAIL_URL, GET_CLAIM_STATUS_UPDATE_URL } from '../util/constants';
import { CLAIM_DETAIL_URL } from '../util/constants';
import Preloader from '../components/Preloader';
import { useNavigate, useParams } from 'react-router-dom';
import { ClaimDetailProps } from '../types/types';
import { formatMoneyWithCurrencySymbol, getAllowanceStatusColor, getAllowanceStatusDetail, getRoleColor, isUnauthorizedRequest } from '../util/utils';

const ClaimDetail: React.FC = () => {
    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { claim_id } = useParams<{ claim_id: string }>(); // Accessing the claim_id from route parameters
    const [claim, setClaim] = useState<ClaimDetailProps>();    

    

    useEffect(() => {
        const fetchClaim = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(CLAIM_DETAIL_URL.replace('{claim_id}', claim_id ?? ''), token);

                const responseData: any = response.data.data;
                setClaim(responseData.claim);
                console.log(responseData.claim);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching claim', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchClaim();
        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };

    // update claim status
    const updateClaim = async (status: string) => {
         // Call API to post data for update status
         const postWorkPlan = async () => {
            setIsLoading(true);
            try {
                await postData(GET_CLAIM_STATUS_UPDATE_URL, token, { 
                    'claim_id': claim?.claim_id,
                    'user_id': claim?.user_id,
                    'status': status
                    
            });
                navigate('/claimDetails/list');
            } catch (error) {
                console.error('An error occurred while processing the request.', error);
    
            } finally {
                setIsLoading(false);
            }
        };
        postWorkPlan();
    }

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Claim Details</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 mt-3">
                                        <div className="info-sec">
                                            <div className="form-details">
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-information-fill mr-2"></i>
                                                            </div>
                                                            <h5>Claim information</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Claim Id</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{claim?.claim_id}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Claim Type</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{claim?.claim_type}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Staff Id</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{claim?.user_id}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Applied By</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{claim?.applied_by} <span className={getRoleColor(claim?.role ?? '')}>{claim?.role}</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Amount</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{formatMoneyWithCurrencySymbol(claim?.amount ?? 0)}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Applied DateTime</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{claim?.applied_date_time}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Current Status</label>
                                                                <label className="col-sm-6 col-md-8 details-value">
                                                                    <div className="user-designation">
                                                                        <span className={getAllowanceStatusColor(claim?.status ?? -1)}>{getAllowanceStatusDetail(claim?.status ?? -1, claim?.approved_by ?? '')}</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item-alt">
                                                            <div className="details-group row form-group">
                                                                <label className="col-sm-6 col-md-4 details-label">Remarks</label>
                                                                <label className="col-sm-6 col-md-8 details-value">{claim?.remarks}</label>
                                                            </div>
                                                        </div>
                                                       { claim?.role === "RSM" && (
                                                         <div className="details-item-alt">
                                                         <div className="details-group row form-group">
                                                             <button className="col-sm-6 col-md-4 details-label btn-reject " onClick={() => updateClaim('reject')}>
                                                             <img src='../../../images/reject.png' alt='check-logo' /> 
                                                             <span className='btn-approve-text'> Reject</span>
                                                             </button>
                                                             <button className="col-sm-6 col-md-8 details-value  btn-approve" onClick={() => updateClaim('approved')}>
                                                             <img src='../../../images/check-mark.png' alt='check-logo' /> 
                                                             <span className='btn-approve-text'>Approve</span>
                                                             </button>
                                                         </div>
                                                     </div>
                                                       )}
                                                    </div>
                                                </div>
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-attachment-line"></i>
                                                            </div>
                                                            <h5>Staff Attachment</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Preloader isLoading={isLoading} />
        </>
    );
};
export default ClaimDetail;
