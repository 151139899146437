
import React, { useEffect, useState, MouseEvent, ChangeEvent, useRef } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, postData } from '../services/ApiService';
import { ERROR_MSG_TIMEOUT, GET_CLAIM_CITYLIST_URL, GET_CLAIM_REG_URL, GET_CLAIM_TYPES_URL } from '../util/constants';
import { useNavigate } from 'react-router-dom';
import { ClaimPlanProps, ClaimTypeProps, ClaimUserSelectProps, ClaimCityListProps, CityListProps } from '../types/types';
import { isStringNullOrEmpty, isSupervisor, isUnauthorizedRequest } from '../util/utils';
import Preloader from '../components/Preloader';
import ShowMessage from '../components/ShowMessage';


const ClaimReg: React.FC = () => {
    const { user, token } = useAuth();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [claimDetails, setClaimDetails] = useState<ClaimPlanProps>();

    const [claimsTypes, setClaimsTypes] = useState<ClaimTypeProps[]>([]);
    const [cityList, setCityList] = useState<CityListProps[]>([]);

    const [formErrors, setFormErrors] = useState<{
        user_id: string;
        claim_type: string;
        origin_city: string;
        destination_city: string;
        amount: string;
        image: string[];
        applied_date: string;
    }>({
        user_id: '',
        claim_type: '',
        origin_city: '',
        destination_city: '',
        amount: '',
        image: [],
        applied_date: ''
    });


    const formRefs = {
        user_id: useRef<HTMLSelectElement>(null),
        claim_type: useRef<HTMLSelectElement>(null),
        origin_city: useRef<HTMLSelectElement>(null),
        destination_city: useRef<HTMLSelectElement>(null),
        amount: useRef<HTMLInputElement>(null),
        applied_date: useRef<HTMLInputElement>(null),
        image: useRef<HTMLInputElement>(null)
    };
    // Screen Initialize Process(will be called once)
    useEffect(() => {


        // Call Api get ClaimsTypes
        const fetchClaims = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(GET_CLAIM_TYPES_URL, token);

                const responseData: any = response.data.data;

                setClaimsTypes(responseData.claimTypes);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching claims', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchClaims();


        // Call Api get ClaimCityList
        const fetchClaimCityList = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(GET_CLAIM_CITYLIST_URL, token);

                const responseData: any = response.data.data;

                setCityList(responseData.cities);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching claims', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchClaimCityList();

        // Initialize Screen
        const initializeWorkPlan = () => {
            // Spread the existing claimDetails object and override the properties
            setClaimDetails((prevWorkPlan: ClaimPlanProps | undefined) => ({
                ...prevWorkPlan!,
                user_id: user?.user_id ?? '',
                claim_type: '',
                origin_city: '',
                destination_city: '',
                additional_amount: '',
                amount: '',
                image: [],
                applied_date: '',
            }));
        };


        // Initialize screen
        initializeWorkPlan();

        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };



    // Handle claim changes
    const handleWorkPlanChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setClaimDetails((prevWorkPlan: ClaimPlanProps | undefined) => ({
            ...prevWorkPlan!,
            [name]: value
        }));
    };

    // Handle claim changes
    const formValidate = () => {
        let userIdError: string = '';
        let claimTypeError: string = '';
        let originError: string = '';
        let destinationError: string = '';
        let amountError: string = '';
        let appliedDateError: string = '';
        let imagesError: string[] = [];
        let isFocusTriggered: boolean = false;

        // Check if user_id is empty
        if (isStringNullOrEmpty(claimDetails?.user_id)) {
            userIdError = 'Promoter ID is required';
            if (!isFocusTriggered) {
                isFocusTriggered = true;
                formRefs.user_id.current?.focus();
            }
        }

        // Check if claim_type is empty
        if (isStringNullOrEmpty(claimDetails?.claim_type)) {
            claimTypeError = 'Claim type is required';
            if (!isFocusTriggered) {
                isFocusTriggered = true;
                formRefs.claim_type.current?.focus();
            }
        }

        // Check if origin is empty only if claim type is "1"
        if (claimDetails?.claim_type === "1" && isStringNullOrEmpty(claimDetails?.origin_city)) {
            originError = 'Origin is required';
            // You can add focus logic for origin if needed
        }

        // Check if destination is empty only if claim type is "1"
        if (claimDetails?.claim_type === "1" && isStringNullOrEmpty(claimDetails?.destination_city)) {
            destinationError = 'Destination is required';
            // You can add focus logic for destination if needed
        }

        // Check if amount is empty
        if (isStringNullOrEmpty(claimDetails?.amount)) {
            amountError = 'Amount is required';
            // You can add focus logic for amount if needed
        }

        if (isStringNullOrEmpty(claimDetails?.applied_date)) {
            appliedDateError = 'Applied date is required ';
        }

        // Check if images are empty
        if (!claimDetails?.image || claimDetails?.image?.length === 0) {
            imagesError.push('At least one image is required');
        }

        // Store error values
        setFormErrors({
            user_id: userIdError,
            claim_type: claimTypeError,
            origin_city: originError,
            destination_city: destinationError,
            amount: amountError,
            image: imagesError,
            applied_date: appliedDateError
        });

        // Check if any errors exist
        if (
            !isStringNullOrEmpty(userIdError) ||
            !isStringNullOrEmpty(claimTypeError) ||
            !isStringNullOrEmpty(originError) ||
            !isStringNullOrEmpty(destinationError) ||
            !isStringNullOrEmpty(amountError) ||
            !isStringNullOrEmpty(appliedDateError) ||
            imagesError.length > 0

        ) {
            console.log('Validation failed. Errors:', formErrors);
            return false;
        }

        return true;
    };


    // Register WorkPlan button
    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // Perform validation
        if (!formValidate()) {
            return;
        }


        // Call API to post data for claim registration
        const postWorkPlan = async () => {
            setIsLoading(true);
            try {
                await postData(GET_CLAIM_REG_URL, token, {
                    'user_id': claimDetails?.user_id,
                    'claim_type': claimDetails?.claim_type,
                    'applied_date': claimDetails?.applied_date,
                    'origin_city': claimDetails?.origin_city,
                    'destination_city': claimDetails?.destination_city,
                    'amount': claimDetails?.amount,
                    'additional_amount': claimDetails?.additional_amount,
                    // 'image1': claimDetails?.image[0],
                    // 'image2': claimDetails?.image[1],
                    // 'image3': claimDetails?.image[2],
                    'remarks': '',
                    'status': "0",
                });
                navigate('/claim/list');
            } catch (error) {
                console.error('An error occurred while processing the request.', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                } else {
                    setError('An error occurred while processing the request. Please try again later.');
                }
            } finally {
                setIsLoading(false);
            }
        };
        postWorkPlan();
    };

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Claim Registration</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 mt-3">
                                        <div className="info-sec">
                                            <div className="form-details">

                                                <ShowMessage message={error} type={"error"} clearMessage={() => setError(null)} />

                                                {
                                                    isSupervisor(user?.role_id ?? 0)
                                                        ? (
                                                            <>
                                                                <WorkPlanElement
                                                                    formErrors={formErrors}
                                                                    formRefs={formRefs}
                                                                    claimDetails={claimDetails}
                                                                    claimsTypes={claimsTypes}
                                                                    cityList={cityList}
                                                                    handleWorkPlanChange={handleWorkPlanChange}
                                                                />
                                                            </>
                                                        ) : (<></>)
                                                }
                                                {
                                                    !isSupervisor(user?.role_id ?? 0)
                                                        ? (
                                                            <>
                                                                <WorkPlanElement
                                                                    formErrors={formErrors}
                                                                    formRefs={formRefs}
                                                                    claimDetails={claimDetails}
                                                                    claimsTypes={claimsTypes}
                                                                    cityList={cityList}
                                                                    handleWorkPlanChange={handleWorkPlanChange}
                                                                />
                                                            </>
                                                        ) : (<></>)
                                                }

                                            </div>
                                        </div>
                                        <div className="form-actions px-4 mb-3 ">
                                            <div className="row justify-content-end">
                                                <div className="col-md-4 col-sm-8 d-flex justify-content-between gap-3">
                                                    <button className='btn btn-tertiary' onClick={handleGoBack}>Discard Changes</button>
                                                    <button className='btn btn-primary' onClick={handleSubmit}>Register Claim</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <Preloader isLoading={isLoading} />
        </>
    );
};
export default ClaimReg;

// Get Claim Element
const WorkPlanElement: React.FC<{
    formErrors: { user_id: string, claim_type: string, origin_city: string, destination_city: string, amount: string, image: string[], applied_date: string };
    formRefs: { user_id: React.RefObject<HTMLSelectElement>, claim_type: React.RefObject<HTMLSelectElement>, origin_city: React.RefObject<HTMLSelectElement>, destination_city: React.RefObject<HTMLSelectElement>, amount: React.RefObject<HTMLInputElement>, applied_date: React.RefObject<HTMLInputElement>, image: React.RefObject<HTMLInputElement>; };
    claimDetails: ClaimPlanProps | undefined;
    claimsTypes: ClaimUserSelectProps[];
    cityList: ClaimCityListProps[];
    handleWorkPlanChange: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
}> = ({ formErrors, formRefs, claimDetails, claimsTypes, cityList, handleWorkPlanChange }) => {


    const [images, setImages] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Handle Upload Image Changes
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const selectedImages = Array.from(files).filter(file => file.type.startsWith('image/'));
            if (images.length + selectedImages.length > 3) {
                setErrorMessage('You can only upload a maximum of 3 images.');
                return;
            }

            const readerPromises = selectedImages.map(selectedImage => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        if (event.target && typeof event.target.result === 'string') {
                            // Resize and encode the image
                            resizeAndEncodeImage(event.target.result, 800, 600) // Set desired dimensions
                                .then(resizedImage => resolve(resizedImage))
                                .catch(error => reject(error));
                        } else {
                            reject(new Error('Failed to read image file.'));
                        }
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(selectedImage);
                });
            });

            Promise.all(readerPromises)
                .then(newImageUrls => {
                    setImages(prevImages => [...prevImages, ...selectedImages]);
                    // Update claimDetails.image if needed
                    if (claimDetails) {
                        claimDetails.image = [...(claimDetails.image ?? []), ...newImageUrls];
                    }
                })
                .catch(error => {
                    console.error('Error reading image file:', error);
                    // Handle error
                });
        }
    };

    // reduce image url size
    const resizeAndEncodeImage = (imageUrl: string, maxWidth: number, maxHeight: number): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;
                const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height);
                canvas.width = img.width * scaleFactor;
                canvas.height = img.height * scaleFactor;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.onerror = reject;
            img.src = imageUrl;
        });
    };


    // Handle Remove Image Changes
    const handleRemoveImage = (index: number) => {
        // Ensure claimDetails is defined and has an image property
        if (claimDetails && claimDetails.image) {
            // Create a new array without the image at the specified index
            const newImageUrls = claimDetails.image.filter((_, i) => i !== index);
            claimDetails.image = newImageUrls;
        }

        // Remove the image from the images state
        setImages(prevImages => {
            const newImages = prevImages.filter((_, i) => i !== index);
            setErrorMessage('');
            return newImages;
        });
    };

    // Handler for individual amount changes
    const handleAmountChange = (fieldName: 'claim_amount' | 'additional_amount') => (e: ChangeEvent<HTMLInputElement>) => {

        const newValue = parseFloat(e.target.value) || 0;

        // Calculate total amount synchronously
        const claimAmount = fieldName === 'claim_amount' ? newValue : parseFloat(claimDetails?.claim_amount || '0');
        const additionalAmount = fieldName === 'additional_amount' ? newValue : parseFloat(claimDetails?.additional_amount || '0');
        const totalAmount = claimAmount + additionalAmount;

        // Update individual amount and total amount synchronously
        handleWorkPlanChange({
            target: {
                name: fieldName,
                value: newValue.toString()
            }
        } as ChangeEvent<HTMLInputElement>);

        handleWorkPlanChange({
            target: {
                name: 'amount',
                value: totalAmount.toString()
            }
        } as ChangeEvent<HTMLInputElement>);
    };

    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="title">
                        <div className="icon-sec">
                            <i className="ri-information-fill mr-2"></i>
                        </div>
                        <h5>Primary information</h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className='forms row'>
                        <div className="forms row">
                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">User ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='user_id'
                                    placeholder='Enter User Id'
                                    title='User Id'
                                    defaultValue={claimDetails?.user_id}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Claim Date *</label>
                                <input
                                    type="date"
                                    className={`form-control ${formErrors?.applied_date ? 'is-invalid' : ''}`}
                                    name='applied_date'
                                    placeholder='Enter Date'
                                    title='applied_date'
                                    ref={formRefs.applied_date}
                                    value={claimDetails?.applied_date}
                                    onChange={handleWorkPlanChange}
                                />
                                {formErrors.applied_date && <div className="invalid-feedback">{formErrors.applied_date}</div>}
                            </div>
                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Claim Type *</label>
                                <select
                                    className={`form-select ${formErrors.claim_type ? 'is-invalid' : ''}`}
                                    name='claim_type'
                                    title='claim_type'
                                    ref={formRefs.claim_type}
                                    value={claimDetails?.claim_type}
                                    onChange={handleWorkPlanChange}
                                >
                                    <option value="" defaultChecked >Choose type</option>
                                    {claimsTypes?.map(claimType => (
                                        <option key={claimType.id} value={claimType.id}>{claimType.claim_type} </option>
                                    ))}
                                </select>
                                {formErrors.claim_type && <div className="invalid-feedback">{formErrors.claim_type}</div>}
                            </div>
                            {claimDetails?.claim_type !== "1" && (
                                <div className="col-md-6 col-sm-6 form-group">
                                    <label className="form-label">Claim Amount *</label>
                                    <input type="text"
                                        className={`form-control ${formErrors.amount ? 'is-invalid' : ''}`}
                                        name='claim_amount'
                                        placeholder='Enter amount'
                                        title='claim_amount'
                                        ref={formRefs?.amount}
                                        value={claimDetails?.claim_amount}
                                        onChange={handleAmountChange('claim_amount')}
                                    />
                                    {formErrors.amount && <div className="invalid-feedback">{formErrors.amount}</div>}
                                </div>

                            )}

                            {claimDetails?.claim_type === "1" && (
                                <>
                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label className="form-label">Origin *</label>
                                        <select
                                            className={`form-select ${formErrors.origin_city ? 'is-invalid' : ''}`}
                                            name='origin_city'
                                            title='origin_city'
                                            ref={formRefs.origin_city}
                                            value={claimDetails?.origin_city}
                                            onChange={handleWorkPlanChange}
                                        >
                                            <option value="" defaultChecked >Choose City</option>
                                            {cityList?.map(city => (
                                                <option key={city.id} value={city.id}>{city.city_name} </option>
                                            ))}
                                        </select>
                                        {formErrors.origin_city && <div className="invalid-feedback">{formErrors.origin_city}</div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label className="form-label">Destination *</label>
                                        <select
                                            className={`form-select ${formErrors.destination_city ? 'is-invalid' : ''}`}
                                            name='destination_city'
                                            title='destination_city'
                                            ref={formRefs.destination_city}
                                            value={claimDetails?.destination_city}
                                            onChange={handleWorkPlanChange}
                                        >
                                            <option value="" defaultChecked >Choose City</option>
                                            {cityList?.map(city => (
                                                <option key={city.id} value={city.id}>{city.city_name} </option>
                                            ))}
                                        </select>
                                        {formErrors.destination_city && <div className="invalid-feedback">{formErrors.destination_city}</div>}
                                    </div>

                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label className="form-label">Claim Amount *</label>
                                        <input type="text"
                                            className={`form-control ${formErrors.amount ? 'is-invalid' : ''}`}
                                            name='claim_amount'
                                            placeholder='Enter amount'
                                            title='claim_amount'
                                            ref={formRefs?.amount}
                                            value={claimDetails?.claim_amount}
                                            onChange={handleAmountChange('claim_amount')}
                                        />
                                        {formErrors.amount && <div className="invalid-feedback">{formErrors.amount}</div>}
                                    </div>
                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label className="form-label">Additional Amount (Optional)</label>
                                        <input type="text"
                                            className={`form-control`}
                                            name='additional_amount'
                                            placeholder='Enter amount'
                                            title='additional_amount'
                                            ref={formRefs?.amount}
                                            value={claimDetails?.additional_amount}
                                            onChange={handleAmountChange('additional_amount')}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-6 form-group">
                                        <label className="form-label">Total Claim Amount *</label>
                                        <input type="text"
                                            className={`form-control ${formErrors.amount ? 'is-invalid' : ''}`}
                                            name='amount'
                                            placeholder='Enter amount'
                                            title='amount'
                                            ref={formRefs?.amount}
                                            value={claimDetails?.amount}
                                            onChange={handleWorkPlanChange}
                                        />
                                        {formErrors.amount && <div className="invalid-feedback">{formErrors.amount}</div>}
                                    </div>
                                </>
                            )}
                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Upload Receipt *</label>
                                <div className="image-upload">
                                    <input type="file" id="upload" className="upload" accept="image/*" ref={formRefs?.image} multiple onChange={handleImageChange} />
                                    <label htmlFor="upload" className={`upload-label ${errorMessage ? 'error-label' : ''}`}>
                                        <img src='../../../images/upload.png' alt="upload logo" className='upload-img' />
                                        <span className="upload-text">Upload image</span>
                                    </label>
                                    <div className="image-list">
                                        {images.map((image, index) => (
                                            <div key={index} className="uploaded-image">
                                                <span>{image.name}</span>
                                                <button className="remove-button" onClick={() => handleRemoveImage(index)}>x</button>
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                                    </div>
                                </div>
                                {formErrors.image[0] && <div className="error-message">{formErrors.image[0]}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


