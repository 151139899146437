import React, { useEffect, useRef } from 'react';
import { isStringNullOrEmpty } from '../util/utils';
import { ERROR_MSG_TIMEOUT } from '../util/constants';

// Define props interface
interface ShowMessageProps {
    message: string | null;
    type: 'success' | 'error' | 'warning' | 'info'; // Define types for message type
    timeout?: number; // Optional timeout prop
    clearMessage?: () => void;
}

// Message component
const ShowMessage: React.FC<ShowMessageProps> = ({ message, type, timeout = ERROR_MSG_TIMEOUT, clearMessage }) => {
    const messageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Focus on the message when it mounts
        if (messageRef.current) {
            messageRef.current.focus();
        }

        // Error message display timeout
        if (message && clearMessage) {
            // After displaying error for defined seconds, reset error
            const timer = setTimeout(() => {
                clearMessage();
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, [message]);

    const getMessageClass = (): string => {
        switch (type) {
            case 'success':
                return 'alert alert-success';
            case 'error':
                return 'alert alert-danger';
            case 'warning':
                return 'alert alert-warning';
            case 'info':
                return 'alert alert-info';
            default:
                return 'alert';
        }
    };

    return (
        <>
            {!isStringNullOrEmpty(message) ? (
                <>
                    <div tabIndex={-1} ref={messageRef} className={getMessageClass()} aria-live="polite" role="alert">
                        {message}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default ShowMessage;
