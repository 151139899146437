import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import FooterMenu from "./FooterMenu";

const Layout: React.FC = () => {
    return (
        <div className="mobile-only">
            <Header />
            <Outlet />
            <FooterMenu />
        </div>
    );
};

export default Layout;