import React from 'react';
import { PopupProps } from '../types/types';

const ImagePopup: React.FC<PopupProps> = ({  open , close }) => {
    const togglePopup = () => {
        close(false); // Close the popup by passing `false` to the close function
    };
    return (
        <div className='overlay'>
        <div className={`image-popup ${open && "image-open"}`}>
            <button onClick={togglePopup}> 
            <img src="../../../images/close.png" alt="Close-icon" width={15} />
            </button>
             <div className="popup-content">
                <img src="../../../images/ad.jpg" alt="ad-img" width={300} />
             </div>
         </div>
        </div>
       
    );
};

export default ImagePopup;