import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData } from '../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { ClaimProps, WorkProps,DashetailProps } from '../types/types';
import { getAllowanceStatus, getAllowanceStatusColor, isUnauthorizedRequest } from '../util/utils';
import ViewDetailsButton from './ViewDetailsButton';
import { DASHBOARD_DATA } from '../util/constants';
import Preloader from './Preloader';
import ImagePopup from './ImagePopup';

const WidgetsCount:React.FC  = () => {
    const { user, token } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
    const [workPlanWidgets, setWorkPlanWidgets] = useState<WorkProps[]>([]);
    const [claimWidgets, setClaimWidgets] = useState<ClaimProps[]>([]);
    const [dash, setDash] = useState<DashetailProps | undefined>();
    const [role, setRole] = useState<DashetailProps >();

      
    //  show image popup
    const hasImagePopupShownBefore = () => {
        return sessionStorage.getItem('imagePopupShown') === 'true';
    };

     //  show image popup
     const markImagePopupAsShown = () => {
        sessionStorage.setItem('imagePopupShown', 'true');
    };


     useEffect(() => {

        // get workplan  and claim Details
        const fetchDataForWidgets = async () => {
            try {

                

                const response: AxiosResponse<any> = await fetchData(DASHBOARD_DATA.replace('{user_id}', user?.user_id ?? ''), token);
                const responseData: any = response.data;
                const role = responseData.role;


          
                setDash(response.data);
                setRole(role);


                setIsLoading(false);

                 // If loading is false, show the ImagePopup
                 if (!hasImagePopupShownBefore()) {
                    setShowImagePopup(true);
                    markImagePopupAsShown();
                }
            
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                if (isUnauthorizedRequest(error)) {
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchDataForWidgets();
    },[user, token, navigate]);

    return (

  

        <div className="widgets">
            {/* rsm */}
                        {dash?.role == 'rsm'  &&(
             <>
            <div className="widget-group">
                <div className="widget-top aI-C">
                    <div className="widget-icon">
                        <img src="images/widget-1.svg" className="widget-img" alt="widget-1" />
                    </div>
                    
                    <div className="widget-title">Pending Claims</div>
                         
            <div className="widget-value">{dash.pending_claims}</div>
                       
                </div>
                <div className="widget-action">
                    <ViewDetailsButton buttonName='View Details' offcanvas={'offcanvas'} targetssub={'targetssub'} />
                </div>
            </div>
            <div className="widget-group">
                <div className="widget-top aI-C">
                    <div className="widget-icon">
                        <img src="images/widget-2.svg" className="widget-img" alt="widget-1" />
                    </div>
                    <div className="widget-title">Total Pending Orders</div>
                   
                <div className="widget-value">{dash.total_pending_orders}</div>
             
                </div>
                <div className="widget-action">
                <ViewDetailsButton buttonName='View Details' offcanvas={'offcanvas'} targetssub={'targetssub'} />
                </div>
            </div>
            <div className="widget-group">
                <div className="widget-top aI-C">
                    <div className="widget-icon">
                        <img src="images/widget-3.svg" className="widget-img" alt="widget-1" />
                    </div>
                    <div className="widget-title">Total Timesheet</div>
             
                <div className="widget-value">{dash.total_timesheet}</div>
                                </div>
                <div className="widget-action">
                <ViewDetailsButton buttonName='View Details' offcanvas={'offcanvas'} targetssub={'targetssub'} />
                </div>
            </div>
            <div className="widget-group">
                <div className="widget-top aI-C">
                    <div className="widget-icon">
                        <img src="images/widget-4.svg" className="widget-img" alt="widget-1" />
                    </div>
                    <div className="widget-title">Total Workplan</div>
                  
                <div className="widget-value">{dash.total_workplan}</div>
                                   </div>
                <div className="widget-action">
                <ViewDetailsButton buttonName='View Details' offcanvas={'offcanvas'} targetssub={'targetssub'} />
                </div>
            </div>
            </>
              )}



{/* supervisor */}
{dash?.role == 'supervisor' && (
<>
 <div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-5.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Pending Claims</div>
     <div className="widget-value">{dash.pending_claims}</div>
 </div>
</div>

<div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-6.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Total Pending Orders</div>
     <div className="widget-value">{dash.total_pending_orders}</div>
 </div>

</div>

</>
)}


{/* Dist */}
{dash?.role == 'Dist' && (
<>
 <div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-5.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Pending Claims</div>
     <div className="widget-value">{dash.pending_claims}</div>
 </div>
</div>

<div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-6.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Total Orders</div>
     <div className="widget-value">{dash.total_pending_orders}</div>
 </div>

</div>

</>
)}

{/* stockist */}
{dash?.role == 'stocky' && (
<>
 <div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-5.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Total Deliverd Orders</div>
     <div className="widget-value">{dash.total_deliverd_orders}</div>
 </div>
</div>

<div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-6.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Total Pending Order</div>
     <div className="widget-value">{dash.total_pending_orders}</div>
 </div>

</div>

</>
)}

{/* stockist */}
{dash?.role == 'promoter' && (
<>
 <div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-5.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Total Claim</div>
     <div className="widget-value">{dash.total_claim}</div>
 </div>
</div>

<div className="widget-group">
 <div className="widget-top aI-C">
     <div className="widget-icon">
         <img src="images/widget-6.svg" className="widget-img" alt="widget-1" />
     </div>
     <div className="widget-title">Total Workplan</div>
     <div className="widget-value">{dash.total_workplan}</div>
 </div>

</div>

</>
)}
           
        </div>

    );


};

export default WidgetsCount;