import { AxiosError } from "axios";
import { AllowanceStatus, ApprovalStatus,OrderStatus, Role } from "../enums/enums";
import moment from "moment";

// Function to format money
export const formatMoney = (amount: number): string => {
  return amount.toLocaleString("en-IN");
};

// Function to format money with currency symbol
export const formatMoneyWithCurrencySymbol = (amount: number): string => {
  return amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });
};

// Function to generate a random number between min and max
export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Function to check given string is null or empty
export const isStringNullOrEmpty = (
  str: string | null | undefined
): boolean => {
  return !str || str === null || str === "" || !str.trim();
};

// Function to check given object is null or empty
export const isObjectNull = (obj: any): boolean => {
  return obj === null;
};

// Function to capitalize the first letter of a string
export const capitalize = (str: string | null): string => {
  // Check if the string is not empty
  if (
    !isStringNullOrEmpty(str) &&
    typeof str === "string" &&
    str.trim().length > 0
  ) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    // If the string is empty or not a string, return empty
    return "";
  }
};

// Function to Return the first character of the string
export const getFirstLetter = (str: string | null): string => {
  // Check if the string is not empty
  if (
    !isStringNullOrEmpty(str) &&
    typeof str === "string" &&
    str.trim().length > 0
  ) {
    // Return the first character of the string
    return capitalize(str.trim()).charAt(0);
  } else {
    // If the string is empty or not a string, return empty
    return "";
  }
};

// Function to convert date time format
// (Example: Tue, Mar 23, 2024, 8:04 AM)
export const convertDateTime = (dateStr: string): string => {
  // Create a new Date object from the date string
  const date = new Date(dateStr);

  // Define options for date formatting
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short", // Short weekday name (e.g., Tue)
    day: "2-digit", // Two-digit day of the month (e.g., 30)
    month: "short", // Short month name (e.g., Mar)
    year: "numeric", // Full numeric year (e.g., 2024)
    hour: "numeric", // Numeric hour (e.g., 10)
    minute: "2-digit", // Two-digit minute (e.g., 30)
    hour12: true, // Use 12-hour clock (true) or 24-hour clock (false)
  };

  // Format the date using toLocaleString() method with options
  return date.toLocaleString("en-IN", options);
};

// Function to convert Date to String(YYYY-MM-DD)
export const convertDateToYMDString = (date: moment.Moment): string => {
  const formattedDate = date.format("YYYY-MM-DD");
  return formattedDate;
};

// Function to get status color
export const getStatusColor = (status: string) => {
  switch (status) {
    case "Resigned":
      return "danger";

    case "Active":
      return "success";
  }
};

// Function to get role color
export const getRoleColor = (role: string) => {
  switch (role) {
    case "MD":
      return "badge badge-color-1";
    case "ADMIN":
      return "badge badge-color-2";
    case "RSM":
      return "badge badge-color-3";
    case "SUPERSTOCKY":
      return "badge badge-color-4";
    case "SUPERVISOR":
      return "badge badge-color-5";
    case "PROMOTER":
      return "badge badge-color-6";
    case "DIST":
      return "badge badge-color-7";
    case "SO":
      return "badge badge-color-8";
    case "GUEST":
      return "badge badge-color-9";
    default:
      return "";
  }
};

export const getRoleBgColor = (role_id: number) => {
  switch (role_id) {
    case 1:
      return " bg-light-1";
    case 2:
      return " bg-light-2";
    case 3:
      return " bg-light-3";
    case 4:
      return " bg-light-4";
    case 5:
      return " bg-light-5";
    case 6:
      return " bg-light-6";
    case 7:
      return " bg-light-7";
    case 8:
      return " bg-light-8";
    case 9:
      return " bg-light-9";
    default:
      return "";
  }
};

// Get Allowance Status Display Name
export const getAllowanceStatus = (status: AllowanceStatus): string => {
  switch (status) {
    case AllowanceStatus.Pending:
      return "Pending";
    case AllowanceStatus.Approved:
      return "Approved";
    case AllowanceStatus.Rejected:
      return "Rejected";
    case AllowanceStatus.Paid:
      return "Paid";
    default:
      return "Unknown";
  }
};

// Get Allowance Status Display Name
export const getAllowanceStatusDetail = (
  status: AllowanceStatus,
  approved_by: string
): string => {
  let allowanceStatus = getAllowanceStatus(status);
  if (AllowanceStatus.Approved === status) {
    allowanceStatus += " by " + approved_by;
  }
  return allowanceStatus;
};

// Function to get allowance status color
export const getAllowanceStatusColor = (status: number) => {
  switch (status) {
    case AllowanceStatus.Pending:
      return "badge bg-secondary";
    case AllowanceStatus.Approved:
      return "badge text-bg-success";
    case AllowanceStatus.Rejected:
      return "badge text-bg-danger";
    case AllowanceStatus.Paid:
      return "badge text-bg-success";
    default:
      return "";
  }
};

// Function to get order status color
export const getOrderStatusColor = (status: any) => {
  switch (status) {
    case OrderStatus.Pending:
      return "badge bg-secondary";
    case OrderStatus.APPROVED:
      return "badge text-bg-success";
    case OrderStatus.REJECT:
      return "badge text-bg-danger";
    case OrderStatus.OPEN:
      return "badge text-bg-success";
    default:
      return "";
  }
};

// Function to check if it is unauthorized request
export const isUnauthorizedRequest = (error: any) => {
  return (
    error instanceof AxiosError &&
    error.response &&
    error.response.status === 401
  );
};

// Get Approval Status Display Name
export const getApprovalStatus = (status: ApprovalStatus): string => {
  switch (status) {
    case ApprovalStatus.Pending:
      return "Pending";
    case ApprovalStatus.Approved:
      return "Approved";
    case ApprovalStatus.Rejected:
      return "Rejected";
    default:
      return "Unknown";
  }
};

// Function to get Approval status color
export const getApprovalStatusColor = (status: number) => {
  switch (status) {
    case ApprovalStatus.Pending:
      return "badge bg-secondary";
    case ApprovalStatus.Approved:
      return "badge text-bg-success";
    case ApprovalStatus.Rejected:
      return "badge text-bg-danger";
    default:
      return "";
  }
};

// Function to check the user is supervisor
export const isSupervisor = (role_id: number) => {
  return Role.SUPERVISOR === role_id;
};

// Function to check the user is promoter
export const isPromoter = (role_id: number) => {
  return Role.PROMOTER === role_id;
};

// Function to determine the visibility of the 'Create Workplan' button
export const isDispCreateWorkPlanButton = (role_id: number) => {
  return (
    Role.SUPERVISOR === role_id ||
    Role.PROMOTER === role_id ||
    Role.SO === role_id
  );
};
