import React, { useRef, useState, useEffect } from "react";
import { useAuth } from '../context/AuthProvider';
import Preloader from '../components/Preloader';
import { useNavigate } from "react-router-dom";
import ShowMessage from "../components/ShowMessage";

interface LoginForm {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { login, error, isLoading } = useAuth();
    const [formData, setFormData] = useState<LoginForm>({
        email: '',
        password: ''
    });

    const emailRef = useRef<HTMLInputElement>(null);    // Ref for email input

    useEffect(() => {
        // Focus on email input when component mounts
        if (emailRef.current) {
            emailRef.current.focus();
        }
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isLoginSuccess: boolean = await login(formData.email, formData.password);
        if (isLoginSuccess) {
            // Navigate to Dashboard
            navigate('/', { replace: true });
        }
    }

    return (
        <div className="mobile-only">
            <div id="main-wrapper" className="login-form">
                <div className="hero-wrap min-vh-100">
                    <div className="hero-mask opacity-4 bg-dark"></div>
                    <div
                        className="hero-bg hero-bg-scroll"
                        style={{ backgroundImage: "url('./images/logo-bg.jpg')" }}
                    ></div>

                    <form onSubmit={handleSubmit} action="/">
                        <div className="hero-content d-flex min-vh-100">
                            <div className="container my-auto">
                                <div className="row ">
                                    <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                                        <div className="bg-white shadow-md rounded p-4 px-sm-5 mt-4">
                                            <div className="logo">
                                                <a
                                                    className="d-flex justify-content-center"
                                                    href="#!"
                                                    title="Logo"
                                                >
                                                    <img src="images/logo.svg" alt="Logo" />
                                                </a>
                                            </div>
                                            <hr className="mx-n4 mx-sm-n5" />
                                            <h5 className="text-center">Welcome Back</h5>
                                            <p className="lead text-center">
                                                We are glad to see you again!
                                            </p>

                                            <ShowMessage message={error} type={"error"} />

                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <label htmlFor="Login" className="form-label">
                                                        Email
                                                    </label>
                                                    <div className="input-group input-group-merge merge-left  ">
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            ref={emailRef}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="email@domain.com"
                                                            value={formData.email}
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                        <div className="input-group-text order-first">
                                                            <i className="ri-mail-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label htmlFor="password" className="form-label">
                                                        Password
                                                    </label>
                                                    <div className="input-group input-group-merge merge-left  ">
                                                        <input
                                                            type="password"
                                                            id="password"
                                                            className="form-control"
                                                            placeholder="********"
                                                            value={formData.password}
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                        <div className="input-group-text order-first">
                                                            <i className="ri-lock-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <a
                                                            href="#!"
                                                            className="text-link float-end dt-link _600"
                                                        >
                                                            Forgot Password?
                                                        </a>
                                                    </div>
                                                    <div className="col-md-12 form-group">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                        >
                                                            Login to Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >

            <Preloader isLoading={isLoading} />
        </div >
    );
};

export default Login;
