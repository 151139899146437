import axios, { AxiosResponse } from "axios";

// Define base url
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

// Define common headers
axios.defaults.headers.common["Accept"] = "application/vnd.api+json";
axios.defaults.headers.common["Content-Type"] = "application/vnd.api+json";

// Function to post data to the API
export const postDataWithoutToken = async (
  apiUrl: string,
  data: any
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(apiUrl, data);
    console.log(response);

    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch data from the API
export const fetchData = async (
  apiUrl: string,
  token: string | null
): Promise<any> => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response: AxiosResponse<any> = await axios.get(apiUrl, {
      headers,
    });
    console.log(response);

    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch data from the API
export const fetchDataWithParams = async (
  apiUrl: string,
  token: string | null,
  params: any
): Promise<any> => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response: AxiosResponse<any> = await axios.get(apiUrl, {
      headers,
      params,
    });
    console.log(params);
    console.log(response);

    return response;
  } catch (error) {
    throw error;
  }
};

// Function to post data to the API
export const postData = async (
  apiUrl: string,
  token: string | null,
  data: any
): Promise<any> => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response: AxiosResponse<any> = await axios.post(apiUrl, data, {
      headers,
    });
    console.log(response);

    return response;
  } catch (error) {
    throw error;
  }
};

// Add more functions as needed for other API endpoints
