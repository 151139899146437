import React from 'react';

interface ImageComponentProps {
    imageName: string;
    imageType: string;
    imageData: string;
    containerClassName?: string;    // Optional
    imageClassName?: string;    // Optional
}

const ImageComponent: React.FC<ImageComponentProps> = ({ imageName, imageType, imageData, containerClassName = "mb-3", imageClassName = "img-fluid shadow" }) => {
    return (
        <>
            {imageData && (
                <div className={containerClassName}>
                    <img
                        src={`data:${imageType};base64,${imageData}`}
                        alt={imageName}
                        className={imageClassName}
                    />
                </div>
            )}
        </>
    );
};

export default ImageComponent;
