import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemProps } from '../types/types';
import { useAuth } from '../context/AuthProvider';
import { menuRoleMappings } from '../util/menuRoleMappings';

const NavItem: React.FC<NavItemProps> = ({ to, iconClass, title }) => (
    <NavLink to={to} className={({ isActive, isPending }) =>
        `nav-item ${isPending ? "pending" : isActive ? "active" : ""}`
    }>
        <i className={iconClass}></i>
        <span>{title}</span>
    </NavLink >
);

const FooterMenu: React.FC = () => {
    const { user } = useAuth();

    return (
        <nav className="footer-mob-menu">
            <NavLink to="/" end className={({ isActive, isPending }) =>
                `nav-item ${isPending ? "pending" : isActive ? "active" : ""}`
            }>
                <i className="ri-home-3-line"></i><span>Home</span>
            </NavLink>
            {Object.entries(menuRoleMappings).map(([path, { roles, iconClass, title, dispFooter }]) =>
                roles.includes(user?.role_id ?? 0) && dispFooter && <NavItem key={path} to={path} iconClass={iconClass} title={title} />
            )}
        </nav>
    );
};

export default FooterMenu;
