import React, { useRef, useState } from 'react';

interface ImageUploadProps {
    handleFileChange: (file: File | null) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ handleFileChange }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            handleFileChange(selectedFile);
            const url = URL.createObjectURL(selectedFile);
            setImageUrl(url);
        }
    };

    const clearFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setImageUrl(null);
            handleFileChange(null);
        }
    };

    return (
        <>
            <div className="d-flex">
                <input
                    id="uploadImage"
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={handleFileChangeEvent}
                    className="form-control"
                />
                <button type="button" className="btn btn-outline-danger ms-1" onClick={clearFile}><i className="ri-delete-bin-line" /></button>
            </div>
            {imageUrl && <img src={imageUrl} alt="Upload" className="mt-3 w-25 h-25 img-thumbnail shadow" />}
        </>
    );
};

export default ImageUpload;
