import { Role } from "../enums/enums";

export const menuRoleMappings: {
  [key: string]: {
    roles: number[];
    iconClass: string;
    title: string;
    dispFooter: boolean;
  };
} = {
//   "/product/list": {
//     roles: [Role.RSM, Role.SUPERSTOCKY, Role.MD],
//     iconClass: "ri-archive-2-line",
//     title: "Products",
//     dispFooter: false,
//   },
  "/shops/list": {
    roles: [Role.RSM, Role.DIST, Role.SUPERVISOR, Role.MD],
    iconClass: "ri-store-line",
    title: "Shops",
    dispFooter: false,
  },
  "/user/list": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.MD,
    ],
    iconClass: "ri-user-line",
    title: "Users",
    dispFooter: true,
  },
  "/workplan/list": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.SO,
      Role.MD,
    ],
    iconClass: "ri-git-fork-line rotate-90",
    title: "Workplan",
    dispFooter: false,
  },
  "/target/list": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.SO,
      Role.MD,
    ],
    iconClass: "ri-flag-2-line",
    title: "Targets",
    dispFooter: false,
  },
  "/claim/list": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.SO,
      Role.MD,
    ],
    iconClass: "ri-survey-line",
    title: "Claim",
    dispFooter: true,
  },
  "/timesheet/list": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.SO,
      Role.MD,
    ],
    iconClass: "ri-calendar-schedule-line",
    title: "Timesheet",
    dispFooter: false,
  },
  "/orders/register": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.MD,
    ],
    iconClass: "ri-shopping-cart-2-line",
    title: "Order",
    dispFooter: true,
  },

  "/orders/register1": {
    roles: [
      Role.RSM,
      Role.SUPERSTOCKY,
      Role.DIST,
      Role.SUPERVISOR,
      Role.PROMOTER,
      Role.MD,
    ],
    iconClass: "ri-shopping-cart-2-line",
    title: "OrderList",
    dispFooter: true,
  },
};
