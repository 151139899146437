import { useAuth } from '../context/AuthProvider';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Preloader from './Preloader';
import { PrivateRouteProps } from '../types/types';

const PrivateRoute: React.FC<PrivateRouteProps> = ({ requiredRoles, children }) => {
    const { user, isAuthenticated, isLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading) {
            if (!isAuthenticated) {
                navigate('/login', { replace: true });
            }
        } else {
            <Preloader isLoading={isLoading} />
        }
    }, [isAuthenticated, navigate, isLoading]);

    // Check if requiredRoles array is not empty
    if (requiredRoles.length !== 0) {
        // Check if user has any of the required roles
        const hasRequiredRole = requiredRoles.some(role => user && user.role_id === role);
        if (!hasRequiredRole) {
            return (
                <div className="container d-flex justify-content-center align-items-center vh-100">
                    <div className="text-center">
                        <h1 className="mb-4">Permission Denied</h1>
                        <p>You don't have permission to access this feature.</p>
                    </div>
                </div>
            );
        }
    }

    return <>{isAuthenticated ? children : null}</>;
}

export default PrivateRoute;
