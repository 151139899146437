import React from 'react';

interface ModalProps {
  isOpen: boolean;
  header: string;
  headerIcon: string;
  children: React.ReactNode;
  onClose: () => void;
  onSaveChanges: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, header, headerIcon, children, onClose, onSaveChanges }) => {
  const modalClassName = isOpen ? 'modal fade show d-block' : 'modal fade';
  const backdropClassName = isOpen ? 'modal-backdrop fade show' : '';

  return (
    <>
      <div className={backdropClassName}></div>
      <div className={modalClassName} tabIndex={-1} role="dialog" style={{ display: isOpen ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"><i className={headerIcon} />{header}</h5>
              <button type="button" className="btn-close" onClick={onClose} />
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Close</button>
              <button type="button" className="btn btn-primary" onClick={onSaveChanges}>Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;