import React from "react";
import LogoutButton from "./LogoutButton";
import { convertDateTime, getFirstLetter } from "../util/utils";
import { useAuth } from "../context/AuthProvider";
import { NavLink } from "react-router-dom";

const Header: React.FC = () => {

    const { user } = useAuth();

    return (
        <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom">
            <div className="log-details">
                <p className="log-label">Logged In</p>
                <p className="log-timestamp">{convertDateTime(user?.last_login ?? '')}</p>
            </div>
            <div className="user-action gap-4">
                <a href="#" className="nav-link">
                    <i className="ri-notification-3-line"></i>
                </a>
                <div className="user-menu dropdown">
                    <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="user-avatar-initial avatar">{getFirstLetter(user?.name ?? '')}</span>
                    </a>
                    <ul className="dropdown-menu text-small shadow">
                        <li><a className="dropdown-item" href="#">Change Password</a></li>
                        <li><a className="dropdown-item" href="#">Settings</a></li>
                        <li><NavLink to="user/profile" className="dropdown-item" >Profile</NavLink></li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li><LogoutButton styleClassName="dropdown-item">Logout</LogoutButton></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;