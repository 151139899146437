// Define constants that are commonly used across project

export const LOGIN_URL = "login";
export const LOGOUT_URL = "logout";

export const TARGET_LIST_URL = "target/list";

export const USER_LIST_URL = "user/list";
export const USER_DETAIL_URL = "user/detail/{user_id}";
export const USER_PROFILE_URL = "user/profile/{user_id}";

export const GET_CLAIM_LIST_URL = "claim/list";
export const GET_CLAIM_DETAIL_URL = "claim/detail/{claim_id}";
export const GET_CLAIM_TYPES_URL = "claim/types"
export const GET_CLAIM_CITYLIST_URL= 'claim/cityList'
export const GET_CLAIM_REG_URL= 'claim/reg'
export const GET_CLAIM_STATUS_UPDATE_URL= 'claim/status/update'
export const GET_ORDER_STATUS_UPDATE_URL= 'order/status/update'


export const CLAIM_LIST_URL = "claim/list";
export const CLAIM_DETAIL_URL = "claim/detail/{claim_id}";
export const CLAIM_DETAILS_REG_URL ="claim/detailForDashBoard/{user_id}"

export const WORKPLAN_LIST_URL = "workPlan/list";
export const WORKPLAN_DETAIL_URL = "workPlan/detail/{workplan_id}";
export const WORKPLAN_REG_INIT_URL = "workPlan/regInit/{user_id}";
export const WORKPLAN_REG_URL = "workPlan/reg";
export const WORKPLAN_UPDATE_INIT_URL = "workPlan/updateInit/{workplan_id}";
export const WORKPLAN_UPDATE_URL = "workPlan/update";
export const WORKPLAN_UPDATE_STATUS_URL = "workPlan/updateStatus";
export const WORKPLAN_ATTACHMENT_URL = "workPlan/attachment";
export const WORKPLAN_ATTACHMENT_UPLOAD_URL = "workPlan/attachment/upload";
export const WORKPLAN_DETAILS_URL= "workPlan/DetailForDashBoard/{user_id}"

export const ORDER_REG_URL = "orders/register";
export const ORDER_List_URL = "orders/list";
export const ORDER_DETAIL_URL = "order/detail/{order_id}";
export const SHOP_DETAIL_URL = "shop/detail/{shop_id}";
export const TIMESHEET_DETAIL_URL = "timesheet/detail/{timesheet_id}";
export const ORDER_ADD_CART = "orders/addCart";
export const ORDER_GET_DEALER = "orders/getDealer";
export const ORDER_REG = "orders/orderPostRegister";
export const ORDER_CART_DEL = "orders/deleteCart";
export const ORDER_DELIVERY_URL = "order/delivery";
export const SHOP_LIST_URL = "shops/list";
export const TIME_SHEET_URL = "timesheet/list";
export const DASHBOARD_DATA = "dashboard";







export const ERROR_MSG_TIMEOUT = 5000; // seconds
