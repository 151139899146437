import React from 'react';
import { LocationProps } from '../types/types';

interface LocationDisplayProps {
    onFetchLocation: () => void;
    location: LocationProps | null
};

const LocationDisplay: React.FC<LocationDisplayProps> = ({ onFetchLocation, location }) => {
    return (
        <div className="card mt-3 mb-3">
            <div className="card-body">
                <h5 className="card-title"><i className="ri-road-map-line pe-1" />Location</h5>
                <div className="mb-3">
                    <label htmlFor="latitude" className="form-label">
                        Latitude:
                    </label>
                    <input
                        type="text"
                        id="latitude"
                        className="form-control"
                        value={location?.latitude || ''}
                        readOnly
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="longitude" className="form-label">
                        Longitude:
                    </label>
                    <input
                        type="text"
                        id="longitude"
                        className="form-control"
                        value={location?.longitude || ''}
                        readOnly
                    />
                </div>
                <div className="text-center">
                    <button className="btn btn-primary" onClick={onFetchLocation}>Get Location</button>
                </div>
            </div>
        </div >
    );
};

export default LocationDisplay;
