
import React, { useEffect, useState, MouseEvent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, postData } from '../services/ApiService';
import { ORDER_DETAIL_URL,GET_ORDER_STATUS_UPDATE_URL,ORDER_DELIVERY_URL} from '../util/constants';
import {NavLink, useNavigate, useParams } from 'react-router-dom';
import { OrderDetailProps } from '../types/types';
import { getRoleColor, isUnauthorizedRequest } from '../util/utils';
import Calendar from 'react-calendar';
import Preloader from '../components/Preloader';
import ImageComponent from '../components/ImageComponent';


import 'react-calendar/dist/Calendar.css';

interface OrderItem {
    id: number;
    total_amount: number;
    quantity: number;
    product_name: string;
  }
  

const OrderDelivery: React.FC = () => {
    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { user_id } = useParams<{ user_id: string }>(); // Accessing the user_id from route parameters
    const [user, setUser] = useState<OrderDetailProps>();
    const [orderData, setOrderData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);



    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(ORDER_DETAIL_URL.replace('{order_id}', user_id ?? ''), token);

                const responseData: any = response.data.data;
                const responseOrderData: any = responseData.orderData;
                const { orderData, totalAmount } = responseData;

                setOrderData(orderData);
                setUser(responseData.order);
                setTotalAmount(totalAmount);


                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching users', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchUser();
        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };

     // update order status
     const updateOrder = async (status: string) => {
        // Call API to post data for update status
        const postWorkPlan = async () => {
           setIsLoading(true);
           try {
               await postData(GET_ORDER_STATUS_UPDATE_URL, token, { 
                   'order_id': user?.order_id,
                   'user_id': user?.user_id,
                   'status': status
                   
           });
               navigate('/orders/register1');
           } catch (error) {
               console.error('An error occurred while processing the request.', error);
   
           } finally {
               setIsLoading(false);
           }
       };
       postWorkPlan();
   }

     // update order status
     const deliverOrder = async (status: string) => {
        // Call API to post data for update status
        const postWorkPlan = async () => {
           setIsLoading(true);
           try {

            const transportDetailInput = document.querySelector('[name="transport_detail"]') as HTMLInputElement;
            const transportDetail = transportDetailInput.value;

            const Remarks = document.querySelector('[name="remarks"]') as HTMLInputElement;
            const remarks = Remarks.value;




               await postData(ORDER_DELIVERY_URL, token, { 
                   'order_id': user?.order_id,
                   'user_id': user?.user_id,
                   'status': status,
                   'transport_detail':transportDetail,
                   'remarks':remarks,

                   
           });
               navigate('/orders/register1');
           } catch (error) {
               console.error('An error occurred while processing the request.', error);
   
           } finally {
               setIsLoading(false);
           }
       };
       postWorkPlan();
   }

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Orders Details</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row">
               <div className="col-md-8">
                <div className="info-sec">
                    <div className="form-details">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="title">
                                        <div className="icon-sec"><i className="ri-information-fill mr-2"></i> </div>
                                            <h5>Primary information</h5>
                                        </div>
                                    </div>
                                <div className="card-body">
                                    <div className="details-item-alt">
                                        <div className="details-group row form-group">
                                            <label className="col-sm-6 col-md-4 details-label">Order ID: {user?.order_id}</label>
                                            <label className="col-sm-6 col-md-4 details-label">Order To: {user?.dealer_name} ({user?.city_name})</label>
                                            <label className="col-sm-6 col-md-8 details-value"></label>
                                        </div>
                                    </div>
                                    <div className="details-item-alt">
                                        <div className="details-group row form-group">
                                            <label className="col-sm-6 col-md-4 details-label">Total Amount</label>
                                            <label className="col-sm-6 col-md-8 details-value">₹{totalAmount} </label>
                          

                                      </div>
                                    </div>
                                </div>
                            </div>

                           
                        
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="title">
                                        <div className="icon-sec"><i className="ri-map-pin-line mr-2"></i> </div>
                                            <h5>Delivery Details</h5>
                                        </div>
                                    </div>
                                <div className="card-body">
                                    <div className="details-item-alt">
                                        <div className="details-group row form-group">
                                            <input type="text"
                                        className={`form-control`}
                                        name='transport_detail'
                                        value={user?.transport_detail}
                                        placeholder='Enter Transport Details'
                                        title='Transport Details'
                                       />
                                        </div>
                                    </div>
                                    <div className="details-item-alt">
                                        <div className="details-group row form-group">
                                            <input type="text"
                                        className={`form-control`}
                                        name='remarks'
                                        placeholder='Enter Remarks'
                                        title='Remarks'
                                        value={user?.remarks}

                                    />
                                        </div>
                                    </div>
                                    {/* <div className="details-item-alt">
                                        <div className="details-group row form-group">
                                            <label className="col-sm-6 col-md-8 details-value">Upload Image</label>
                                        </div>
                                    </div> */}
                                    <div className="text-center">

                                    <button className="btn btn-primary details-value " onClick={() => deliverOrder('DELIVERED')}>
                                                             <img src='../../../images/check-mark.png' alt='check-logo' /> 
                                                             <span className='btn-approve-text'>Send Delivery</span>
                                                             </button>
                </div>
                                </div>
                            </div>
                       
                                    
                               
                            </div>
                        </div>
                    </div>
                </div>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Preloader isLoading={isLoading} />
        </>
    );
};
export default OrderDelivery;
