import React from 'react'
import { ViewButtonProps } from '../types/types';


const ViewDetailsButton: React.FC<ViewButtonProps> = ({ buttonName, offcanvas , targetssub}) => {
    return (
        <div className="btn-view">
             <a href="/" className="btn btn-viewDetails" data-bs-toggle={offcanvas} data-bs-target={`#${targetssub}`}>
                  { buttonName}
            </a>
        </div>
    )
}

export default ViewDetailsButton;