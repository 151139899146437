import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
import { fetchDataWithParams, postData } from '../services/ApiService';
import { ORDER_REG_URL, ORDER_ADD_CART, ORDER_GET_DEALER, ORDER_REG, ORDER_CART_DEL } from '../util/constants';
import { ProductRegisterProps, ProductCategoryListProps, CityFilterProps, DealerFilterProps, CartListProps } from '../types/types';
import ImageComponent from '../components/ImageComponent';
import Preloader from '../components/Preloader';

const OrderRegister: React.FC = () => {
    const { user, token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchBy, setSearchBy] = useState<string>('');
    const [filterCategoryList, setFilterCategoryList] = useState<string>('');
    const [products, setProducts] = useState<ProductRegisterProps[]>([]);
    const [productCategoryList, setProductCategoryList] = useState<ProductCategoryListProps[]>([]);
    const [cityList, setCityList] = useState<CityFilterProps[]>([]);
    const [cityId, setCityId] = useState<string>('');
    const [dealerList, setDealerList] = useState<DealerFilterProps[]>([]);
    const [dealerId, setDealerId] = useState<string>('');
    const [cartData, setCartData] = useState<CartListProps[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [cityError, setCityError] = useState('');
    const [dealerError, setDealerError] = useState('');

    const toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    useEffect(() => {
        handleSearch();
    }, [])

    const calculateTotalAmount = (cartData: CartListProps[]) => {
        let newTotalAmount = 0;
        cartData.forEach((item) => {
            newTotalAmount += item.amount * item.quantity;
        });
        setTotalAmount(newTotalAmount);
    };
    // Search button click event
    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(ORDER_REG_URL, token, {
                searchBy: searchBy,
                user_id: user?.user_id,
                filterCategoryList: filterCategoryList
            });
            const responseData: any = response.data.data;
            setProducts(responseData.products);
            setProductCategoryList(responseData.productCategoryList);
            setFilterCategoryList(responseData.search.filterCategoryList);
            setCityList(responseData.cityList);
            setCartData(responseData.cartData);
            setTotalAmount(responseData.cartTotalAmount);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleCart = async (productId: any) => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(ORDER_ADD_CART, token, {
                productId: productId,
            });
            const responseData: any = response.data.data;
            if (responseData.cart != '') {
                toast.fire({
                    icon: 'success',
                    title: 'Cart Added Successfully'
                }).then(() => {
                    // Reload the page
                    window.location.reload();
                });
            }
        } catch (error) {
            console.error('Error fetching users', error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDealer = async (cityId: any) => {
        setIsLoading(true);
        setCityId(cityId);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(ORDER_GET_DEALER, token, {
                cityId: cityId,
            });
            const responseData: any = response.data.data;
            setDealerList(responseData.dealer);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const formatAmount = (amount: any) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(amount);
    };

    // Event handler to increment quantity
    const handleIncrement = (index: number) => {
        const newCartData = [...cartData];
        const currentQuantity = newCartData[index].quantity;
        const newQuantity = Math.min(currentQuantity + 1, 9);
        const newAmount = newQuantity * newCartData[index].amount;
        newCartData[index] = {
            ...newCartData[index],
            quantity: newQuantity,
            quantityAmount: newAmount
        };
        setCartData(newCartData);
        calculateTotalAmount(newCartData);
    };


    const handleDecrement = (index: number) => {
        const newCartData = [...cartData];
        const currentQuantity = newCartData[index].quantity;
        const newQuantity = Math.max(currentQuantity - 1, 1);
        const newAmount = newQuantity * newCartData[index].amount;
        newCartData[index].quantity = newQuantity;
        newCartData[index].quantityAmount = newAmount;
        setCartData(newCartData);
        calculateTotalAmount(newCartData);
    };

    const handleOrder = async () => {
        setCityError('');
        setDealerError('');
        let errFlg = false;
        if (cityId == "") {
            setCityError('Please select city');
            errFlg = true;
        }
        if (dealerId == "") {
            setDealerError('Please select Dealer')
            errFlg = true;
        }
        if (!errFlg) {
            setIsLoading(true);
            try {
                const response: AxiosResponse<any> = await postData(ORDER_REG, token, {
                    cityId: cityId,
                    dealerId: dealerId,
                    cartData: cartData
                });
                const responseData: any = response.data.data;
                if (responseData.result) {
                    toast.fire({
                        icon: 'success',
                        title: 'Your Order is Placed'
                    }).then(() => {
                        window.location.reload();
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleCartDelete = async (id: any) => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(ORDER_CART_DEL, token, {
                id: id,
                user_id: user?.user_id,

                
            });
            const responseData: any = response.data.data;
            setCartData(responseData.cartData);
            if (responseData.result) {
                toast.fire({
                    icon: 'success',
                    title: 'Your Order is Deleted'
                }).then(() => {
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="content">
                <div className="content-main">
                    <div className="content-wrapper">
                        <div className="title-container border-bottom">
                            <div className="title">
                                <h3>Products <button type="button" className="btn btn-primary ms-3 float-end mb-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Cart</button>
                                </h3>
                            </div>
                        </div>
                        <div className="searchFilterContainer">
                            <div className="input-group input-group-merge merge-right searchGroup">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchBy}
                                    onChange={(e) => setSearchBy(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary searchBtn"
                                    onClick={handleSearch}
                                >
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <button type="button" className="btn btn-filter btn-light" data-bs-toggle="offcanvas" data-bs-target="#filterby">
                                <i className="ri-filter-3-line"></i>
                            </button>
                        </div>
                        <div id="products" className="row row-cols-2 row-cols-sm-2 g-3">
                            {products.map(productMap => (
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="position-relative">
                                            <ImageComponent
                                                imageName={productMap.product_image1}
                                                imageType={productMap.product_image1Type}
                                                imageData={productMap.product_image1Data}
                                                containerClassName="mb-0"
                                                imageClassName="img-fluid shadow rounded-top"
                                            />
                                        </div>
                                        <div className="card-body">
                                            <p className="text-muted mb-2">{productMap.category_name}</p>
                                            <h5 className="card-title tx-16 _600">{productMap.product_name} {productMap.quantity}{productMap.unit}</h5>
                                            <p className="card-text  tx-18 _600">₹ {productMap.amount}</p>
                                            <a
                                                href="#"
                                                className="btn btn-primary btn-block add-cart"
                                                onClick={() => handleCart(productMap.id)}
                                            >
                                                Add Cart
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className="offcanvas offcanvas-bottom" tabIndex={-1} id="filterby" aria-labelledby="FilterLabel">
                    <div className="dragger"></div>
                    <div>
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="FilterLabel">Filters</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="offcanvas-body">
                        <div className="filters">
                            <div className="form-select-group">
                                <select
                                    className="form-select"
                                    aria-label="City"
                                    value={filterCategoryList}
                                    onChange={(e) => setFilterCategoryList(e.target.value)}
                                >
                                    <option value="">Select Category</option>
                                    {productCategoryList.map(productCategoryListMap => (
                                        <option key={productCategoryListMap.id.toString()} value={productCategoryListMap.id.toString()}>{productCategoryListMap.category_name}</option>

                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="offcanvas-footer">
                        <div className="d-flex justify-content-between gap-4">
                            <button className="btn btn-tertiary btn-block" data-bs-dismiss="offcanvas">Cancel</button>
                            <button className="btn btn-primary btn-block" data-bs-dismiss="offcanvas" onClick={handleSearch}>Apply</button>
                        </div>
                    </div>
                </div >
                <div className="offcanvas offcanvas-end" tabIndex={-2} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">Cart</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <label className="col-sm-6 col-md-4 details-label">Delivery To</label>
                        <div className="form-select-group mb-1">
                            <select
                                className={`form-select ${cityError ? 'is-invalid' : ''}`} // Add 'is-invalid' class when there is an error
                                aria-label="City"
                                value={cityId}
                                onChange={(e) => handleDealer(e.target.value)}
                            >
                                <option value="">Select City</option>
                                {cityList.map(cityListMap => (
                                    <option key={cityListMap.id.toString()} value={cityListMap.id.toString()}>{cityListMap.city_name}</option>
                                ))}
                            </select>
                            {cityError && <div className="invalid-feedback">{cityError}</div>} {/* Render error message only when there's an error */}
                        </div>
                        <div className="form-select-group">
                            <select
                                className={`form-select ${dealerError ? 'is-invalid' : ''}`} // Add 'is-invalid' class when there is an error
                                aria-label="Dealer"
                                value={dealerId}
                                onChange={(e) => setDealerId(e.target.value)}
                            >
                                <option value="">Select Dealer</option>
                                {dealerList.map(dealerListMap => (
                                    <option key={dealerListMap.user_id} value={dealerListMap.user_id}>{dealerListMap.name}({dealerListMap.role})</option>
                                ))}
                            </select>
                            {dealerError && <div className="invalid-feedback">{dealerError}</div>} {/* Render error message only when there's an error */}
                        </div>

                        <div className="cart-container mt-3">
                            {cartData.length === 0 ? (
                                <div className="text-center">
                                    Your cart is empty
                                </div>
                            ) : (
                                cartData.map((cartListMap, index) => (
                                    <div className="card mb-3" key={index}> {/* Added key prop here */}
                                        <div className="d-flex justify-content-end">
                                            <button type="button" className="btn-close text-reset" onClick={() => handleCartDelete(cartListMap.id)}></button>
                                        </div>
                                        <div className="row g-0">
                                            <div className="d-flex justify-content-center col-5 col-sm-4 ">
                                            <ImageComponent
                                                imageName={cartListMap.product_image1}
                                                imageType={cartListMap.product_image1Type}
                                                imageData={cartListMap.product_image1Data}
                                                containerClassName="mb-0"
                                                imageClassName="img-fluid shadow rounded-top"
                                            />
                                            </div>
                                            <div className="col-7 col-sm-8">
                                                <div className="card-body">
                                                    <h6 className="card-title name">{cartListMap.product_name} {cartListMap.product_quantity}{cartListMap.unit}</h6>
                                                    <div className="product-price">
                                                        <div className="flex-fill">
                                                            <div className="price">
                                                                <span className="text-success tx-14 _700 text-nowrap sellingprice" id={`amt_lbl_${index}`}>{formatAmount(cartListMap.quantityAmount)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="align-items-center">
                                                            <div className="d-flex justify-content-end align-items-center gap-2">
                                                                <div className="decrement-btn">
                                                                    <button type="button" className="btn btn-light px-2" onClick={() => handleDecrement(index)}>-</button>
                                                                </div>
                                                                <div className="w-25">
                                                                    <input
                                                                        type="text"
                                                                        className="stepper form-control text-center"
                                                                        name='quantity[]'
                                                                        min={1}
                                                                        max={9}
                                                                        placeholder='Enter Quantity'
                                                                        value={cartListMap.quantity} // Bind quantity value
                                                                    />
                                                                </div>
                                                                <div className="increment-btn">
                                                                    <button type="button" className="btn btn-light px-2" onClick={() => handleIncrement(index)}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                            {cartData.length !== 0 && (
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <button type="button" className="btn btn-primary btn-order" onClick={() => handleOrder()}>Order Product</button>
                                    </div>
                                    <div>
                                        <span> Total :</span>
                                        <span className="text-success _700 price1" id="tot-amount">{formatAmount(totalAmount)}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Preloader isLoading={isLoading} />
        </>
    )
};

export default OrderRegister;

