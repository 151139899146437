// ============================
// Claim Screen Related
// ============================
export enum AllowanceStatus {
  Pending,
  Approved,
  Rejected,
  Paid,
}

// ============================
// Roles Related
// ============================
export enum Role {
  ADMIN = 1,
  RSM = 2,
  SUPERSTOCKY = 3,
  DIST = 4,
  SUPERVISOR = 5,
  PROMOTER = 6,
  SO = 7,
  GUEST = 8,
  MD = 9,
}

// ============================
// WorkPlan Screen Related
// ============================
export enum ApprovalStatus {
  Pending,
  Approved,
  Rejected,
}
export enum WorkPlanTarget {
  MYSELF,
  OTHERS,
}
export enum OrderStatus {
  Pending,
  APPROVED,
  REJECT,
  OPEN,
}