import React from 'react';
import LogoutButton from './LogoutButton';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { NavItemProps } from '../types/types';
import { menuRoleMappings } from '../util/menuRoleMappings';

const NavItem: React.FC<NavItemProps> = ({ to, iconClass, title }) => (
    <NavLink to={to} className="nav-items">
        <div className="nav-icon"><i className={iconClass}></i></div>
        <div className="nav-title">{title}</div>
    </NavLink>
);

const NavContainer: React.FC = () => {
    const { user } = useAuth();

    return (
        <div className="nav-container">
            <div className="nav-group">
                <NavItem to="/" iconClass="ri-home-3-line" title="Home" />
                {Object.entries(menuRoleMappings).map(([path, { roles, iconClass, title }]) =>
                    roles.includes(user?.role_id ?? 0) && <NavItem key={path} to={path} iconClass={iconClass} title={title} />
                )}
                <LogoutButton styleClassName="nav-items">
                    <div className="nav-icon"><i className="ri-login-circle-line"></i></div>
                    <div className="nav-title">Logout</div>
                </LogoutButton>
            </div>
        </div>
    );
};

export default NavContainer;
