import React from "react";
import Widgets from "../components/Widgets";
import NavContainer from "../components/NavContainer";
import { useAuth } from "../context/AuthProvider";
import ShowMessage from "../components/ShowMessage";
import WidgetsCount from "../components/WidgetsCount";
import { getRoleBgColor } from "../util/utils";

const Dashboard: React.FC = () => {

    const { user, error } = useAuth();
   

    return (
        <>
            <div className="content">
                <div className="content-main">
                    <div className={`content-wrapper ${getRoleBgColor(user?.role_id ?? 0)}`}>
                        <ShowMessage message={error} type={"error"} />
                        <div className="title-container border-bottom align-items-center">
                            <div className="title">
                                <h6>Hi {user?.name}</h6>
                                <p className="text-muted mb-0">Welcome to Samudhrika Application</p>
                            </div>
                        </div>
                        <div className="content-body">
                            <div className="container">
                                <div className="group-section">
                                    <h5 className="section-title mB50">Dashboard</h5>
                                    <Widgets />
                                </div>
                                <div className="group-section">
                                    <WidgetsCount />
                                </div>
                                {/* <div className="group-section">
                                    <h5 className="section-title">Quick Access</h5>
                                    <QuickLinks />
                                </div> */}
                                <div className="group-section">
                                    <h5 className="section-title">Navigation</h5>
                                    <NavContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-bottom" id="targetssub" aria-labelledby="TargetsLabel">
                <div className="dragger"></div>
                <div>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="TargetsLabel">Targets</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>
                <div className="offcanvas-body">
                    <div className="targets-nav">
                        <div className="target-items">
                            <label className="target-value">Rs 2,00,000</label>
                            <a className="btn btn-block target-btn" href="primary-target.html">
                                <span>Primary Target</span>
                                <span className="icon-sec"><i className="ri-arrow-right-line"></i></span>
                            </a>
                        </div>

                        <div className="target-items">
                            <label className="target-value">Rs 1,50,000</label>
                            <a className="btn btn-block target-btn" href="primary-target.html">
                                <span>Secondary Target</span>
                                <span className="icon-sec"><i className="ri-arrow-right-line"></i></span>
                            </a>
                        </div>
                        <hr />
                        <div className="target-items">
                            <a className="btn btn-block target-btn" href="primary-target.html">
                                <span>Assign Promoter Sales</span>
                                <span className="icon-sec"><i className="ri-arrow-right-line"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;