import React from 'react'
import { UpdateButtonProps } from '../types/types';
import { NavLink } from 'react-router-dom';

const CreateButton: React.FC<UpdateButtonProps> = ({ buttonName, navPath }) => {
    return (
        <div className="content-fab-btn">
            <NavLink to={navPath} className="btn fixed-button">
                <i className="ri-edit-box-line px-1"></i>{buttonName}
            </NavLink>
        </div>
    )
}

export default CreateButton;