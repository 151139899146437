import React, { createContext, useContext, useEffect, useState } from 'react';
import { postData, postDataWithoutToken } from '../services/ApiService';
import { ERROR_MSG_TIMEOUT, LOGIN_URL, LOGOUT_URL } from '../util/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthContextType, LoginUser } from '../types/types';

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<LoginUser | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        const storedToken = localStorage.getItem('token');
        const storedUser = localStorage.getItem('user');

        if (storedToken && storedUser) {
            setIsAuthenticated(true);
            setUser(JSON.parse(storedUser));
            setToken(storedToken);
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsLoading(false);
    }, [isAuthenticated]); // Log isAuthenticated only when it changes

    useEffect(() => {
        if (error) {
            // After displaying error for 5 seconds, reset error
            const timer = setTimeout(() => {
                setError(null);
            }, ERROR_MSG_TIMEOUT);

            return () => clearTimeout(timer);
        }
    }, [error]);

    // Handle login process
    const login = async (email: string, password: string) => {
        let isLoginSuccess: boolean = false;
        setIsLoading(true);
        try {
            // Assuming the API returns user data and a token upon successful login
            const response: AxiosResponse<any> = await postDataWithoutToken(LOGIN_URL, { email, password });

            const responseData: any = response.data.data;
            const token: string = responseData.token;
            const user: LoginUser = responseData.user;

            // Update the state with the authenticated user and token
            setUser(user);
            setToken(token);
            setIsAuthenticated(true);
            setError(null);

            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));

            isLoginSuccess = true;
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && error.response.status === 401) {
                    setError('Login details are not valid in.');
                } else {
                    setError('An error occurred. Please try again later.');
                }
            } else {
                setError('An error occurred. Please try again later.');
            }

            // Clear localStorage
            removeLocalStorage();
        } finally {
            setIsLoading(false);
        }
        return isLoginSuccess;
    };

    // Handle logout process
    const logout = async () => {
        let isLogoutSuccess: boolean = false;
        setIsLoading(true);
        try {
            await postData(LOGOUT_URL, token, {});
        } catch (error) {
            // Handle logout error
            console.error('Logout failed', error);
        } finally {
            // Neglect logout error
            isLogoutSuccess = true;

            // Clear the state
            setIsAuthenticated(false);
            setUser(null);
            setToken(null);
            setError(null);

            // Clear localStorage
            removeLocalStorage();

            setIsLoading(false);
        }
        return isLogoutSuccess;
    };

    // Clear localStorage
    const removeLocalStorage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    };

    const authContextValue: AuthContextType = {
        isAuthenticated,
        user,
        token,
        error,
        isLoading,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};

// Define the useAuth hook
const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };
