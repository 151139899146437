import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { Role } from './enums/enums';
import { ClaimDetail, ClaimList, Dashboard, Login, UserDetail , UserList, UserProfile, WorkPlanAttachment, WorkPlanDetail, WorkPlanList, WorkPlanReg, WorkPlanUpdate, OrderRegister } from './pages';
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./components/Layout";
import ClaimReg from './pages/ClaimReg';
import OrderRegister1 from './pages/OrderList';
import OrderDetail from './pages/OrderDetail';
import OrderDelivery from './pages/OrderDelivery';
import TargetList from './pages/TargetList';
import ShopList from './pages/ShopList';
import ShopDetail from './pages/ShopDetail';
import TimeSheet from './pages/Timesheet';
import TimesheetDetail from './pages/TimesheetDetail';





const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute requiredRoles={[]}>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/user/list",
        element: (
          <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
            <UserList />
          </PrivateRoute>
        ),
      },

      {
        path: "target/list",
        element: (
          <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
            <TargetList />
          </PrivateRoute>
        ),
      },
      {
        path: "timesheet/list",
        element: (
          <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
            <TimeSheet/>
          </PrivateRoute>
        ),
      },
      {
        path: '/claim/list',
        element: (
          <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
            <ClaimList />
          </PrivateRoute>
        ),
      },
      {
        path: '/claim/reg',
        element: (
          <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
            <ClaimReg />
          </PrivateRoute>
        ),
      },
      {
        path: '/workPlan/list',
        element: (
          <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
            <WorkPlanList />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/user/detail/:user_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <UserDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/order/detail/:user_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <OrderDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/order/delivery/:user_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <OrderDelivery />
      </PrivateRoute>
    ),
  },
  {
    path: "/claim/detail/:claim_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <ClaimDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/shop/detail/:shop_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <ShopDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/timesheet/detail/:timesheet_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <TimesheetDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/workplan/detail/:workplan_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <WorkPlanDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/workplan/reg",
    element: (
      <PrivateRoute requiredRoles={[Role.SUPERVISOR, Role.PROMOTER, Role.SO]}>
        <WorkPlanReg />
      </PrivateRoute>
    ),
  },
  {
    path: "/workplan/update/:workplan_id",
    element: (
      <PrivateRoute requiredRoles={[Role.SUPERVISOR, Role.PROMOTER, Role.SO]}>
        <WorkPlanUpdate />
      </PrivateRoute>
    ),
  },
  {
    path: "/workplan/attachment/:workplan_id/:workplan_detail_id",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <WorkPlanAttachment />
      </PrivateRoute>
    ),
  },
  {
    path: "/user/profile",
    element: (
      <PrivateRoute requiredRoles={[]}>
        <UserProfile />
      </PrivateRoute>
    ),
  },
  {
    path: "/orders/register",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <OrderRegister />
      </PrivateRoute>
    ),
  },
  {
    path: "/orders/register1",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <OrderRegister1 />
      </PrivateRoute>
    ),
  },
  {
    path: "shops/list",
    element: (
      <PrivateRoute requiredRoles={[Role.RSM, Role.SUPERSTOCKY, Role.DIST, Role.SUPERVISOR, Role.PROMOTER, Role.SO, Role.MD]}>
        <ShopList />
      </PrivateRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <Login />
    ),
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

function NotFoundPage() {
  return <h1>404 - Page Not Found</h1>;
}

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />;
    </AuthProvider>
  )
}

export default App;
