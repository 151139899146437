
import React, { useEffect, useState, MouseEvent, ChangeEvent, useRef } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, postData } from '../services/ApiService';
import { ERROR_MSG_TIMEOUT, WORKPLAN_REG_INIT_URL, WORKPLAN_REG_URL } from '../util/constants';
import { useNavigate } from 'react-router-dom';
import { WorkPlanUserSelectProps, WorkPlanDetailProps, WorkPlanProps } from '../types/types';
import { convertDateToYMDString, isStringNullOrEmpty, isSupervisor, isUnauthorizedRequest } from '../util/utils';
import { WorkPlanTarget } from '../enums/enums';
import Preloader from '../components/Preloader';
import moment from 'moment';
import ShowMessage from '../components/ShowMessage';

const WorkPlanReg: React.FC = () => {
    const { user, token } = useAuth();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [workPlanTarget, setWorkPlanTarget] = useState<WorkPlanTarget>(WorkPlanTarget.MYSELF);
    const [workPlan, setWorkPlan] = useState<WorkPlanProps>();
    const [workPlanDetail, setWorkPlanDetail] = useState<WorkPlanDetailProps[]>([]);

    const [approverList, setApproverList] = useState<WorkPlanUserSelectProps[]>([]);
    const [promoterList, setPromoterList] = useState<WorkPlanUserSelectProps[]>([]);

    const [formErrors, setFormErrors] = useState<{
        user_id: string;
        approver_id: string;
        title: string[];
        description: string[];
    }>({
        user_id: '',
        approver_id: '',
        title: [],
        description: []
    });
    const formRefs = {
        user_id: useRef<HTMLSelectElement>(null),
        approver_id: useRef<HTMLSelectElement>(null),
        title: useRef<HTMLInputElement[]>([]),
        description: useRef<HTMLTextAreaElement[]>([])
    };

    // Screen Initialize Process(will be called once)
    useEffect(() => {
        // Call API to get data for initialize screen
        const fetchWorkPlanRegInit = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(WORKPLAN_REG_INIT_URL.replace('{user_id}', user?.user_id ?? ''), token);

                const responseData: any = response.data.data;

                setApproverList(responseData.approverList);
                setPromoterList(responseData.promoterList);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching workplan details', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        // Calculate start and end dates for each term based on current date
        const calculateStartEndDate = (): { startDate: moment.Moment, endDate: moment.Moment } => {
            const today: moment.Moment = moment();
            const year: number = today.year();
            const month: number = today.month();
            let day: number = today.date();
            const daysInMonth: number = moment().daysInMonth();

            const firstTermEnd: number = 10;
            const secondTermEnd: number = 20;

            let startDate: moment.Moment;
            let endDate: moment.Moment;
            if (day > secondTermEnd) {
                startDate = moment([year, month + 1, 1]);
                endDate = moment([year, month + 1, firstTermEnd]);
            } else if (day <= firstTermEnd) {
                startDate = moment([year, month, firstTermEnd + 1]);
                endDate = moment([year, month, secondTermEnd]);
            } else {
                startDate = moment([year, month, secondTermEnd + 1]);
                endDate = moment([year, month, daysInMonth]);
            }

            return { startDate, endDate };
        };

        // Generate workplan details from startDate to endDate
        const generateWorkPlanDetails = (startDate: moment.Moment, endDate: moment.Moment): WorkPlanDetailProps[] => {
            // Create an array of dates between startDate and endDate
            const dateList: moment.Moment[] = [];
            let currentDate = moment(startDate);
            while (currentDate <= endDate) {
                dateList.push(moment(currentDate));
                currentDate.add(1, 'days');
            }

            // Create an array of WorkPlanDetailProps and set only the date property
            const workPlanDetails: WorkPlanDetailProps[] = dateList.map(date => ({
                workplan_id: '',
                workplan_detail_id: '',
                date: convertDateToYMDString(date),
                title: '',
                description: ''
            }));

            // Define the size of array after receiving the data
            formRefs.title.current = new Array(workPlanDetails.length);
            formRefs.description.current = new Array(workPlanDetails.length);

            return workPlanDetails;
        };

        // Initialize Screen
        const initializeWorkPlan = () => {
            fetchWorkPlanRegInit();
            const { startDate, endDate } = calculateStartEndDate();
            // Spread the existing workPlan object and override the start_date property
            setWorkPlan((prevWorkPlan: WorkPlanProps | undefined) => ({
                ...prevWorkPlan!,
                user_id: user?.user_id ?? '',
                start_date: convertDateToYMDString(startDate),
                end_date: convertDateToYMDString(endDate),
                approver_id: '',
                approval_status: 0,
                assigned_by: '',
                assigned_by_name: ''
            }));
            const workPlanDetails = generateWorkPlanDetails(startDate, endDate);
            setWorkPlanDetail(workPlanDetails);
        };

        // Initialize screen
        initializeWorkPlan();

        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };

    // Handle workplan changes
    const handleWorkPlanTargetChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        if (WorkPlanTarget.MYSELF.toString() === value) {
            setWorkPlan((prevWorkPlan: WorkPlanProps | undefined) => ({
                ...prevWorkPlan!,
                user_id: user?.user_id ?? '',
                approver_id: '',
                assigned_by: '',
                assigned_by_name: ''
            }));
            setWorkPlanTarget(WorkPlanTarget.MYSELF);
        } else if (WorkPlanTarget.OTHERS.toString() === value) {
            setWorkPlan((prevWorkPlan: WorkPlanProps | undefined) => ({
                ...prevWorkPlan!,
                user_id: '',
                approver_id: '',
                assigned_by: user?.user_id ?? '',
                assigned_by_name: ''
            }));
            setWorkPlanTarget(WorkPlanTarget.OTHERS);
        }
    };

    // Handle workplan changes
    const handleWorkPlanChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setWorkPlan((prevWorkPlan: WorkPlanProps | undefined) => ({
            ...prevWorkPlan!,
            [name]: value
        }));
    };

    // Handle workdetail changes
    const handleWorkPlanDetailChange = (index: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWorkPlanDetail(prevDetails => {
            return prevDetails.map((detail, i) => {
                if (i === index) {
                    return { ...detail, [name as keyof WorkPlanDetailProps]: value };
                }
                return detail;
            });
        });
    };

    // Handle workdetail changes
    const formValidate = () => {
        let userIdError: string = '';
        let approverError: string = '';
        let titleErrors: string[] = [];
        let descriptionErrors: string[] = [];
        let isFocusTriggered: boolean = false;
        // Check if user_id is empty
        if (isStringNullOrEmpty(workPlan?.user_id)) {
            // Handle validation error for user_id
            userIdError = 'Promoter ID is required';
            if (!isFocusTriggered) {
                isFocusTriggered = true;
                formRefs.user_id.current?.focus();
            }
        }
        // Check if approver_id is empty
        if (isStringNullOrEmpty(workPlan?.approver_id)) {
            // Handle validation error for approver_id
            approverError = 'Approver is required';
            if (!isFocusTriggered) {
                isFocusTriggered = true;
                formRefs.approver_id.current?.focus();
            }
        }
        // Validate title and description for each detail
        workPlanDetail.forEach((detail, index) => {
            if (isStringNullOrEmpty(detail.title)) {
                titleErrors[index] = 'Title is required';
                if (!isFocusTriggered) {
                    isFocusTriggered = true;
                    formRefs.title.current[index]?.focus();
                }
            }
            if (isStringNullOrEmpty(detail.description)) {
                descriptionErrors[index] = 'Description is required';
                if (!isFocusTriggered) {
                    isFocusTriggered = true;
                    formRefs.description.current[index]?.focus();
                }
            }
        });

        // Store error values
        setFormErrors({
            user_id: userIdError,
            approver_id: approverError,
            title: titleErrors,
            description: descriptionErrors
        });

        // Check if any errors exist
        if (!isStringNullOrEmpty(userIdError) || !isStringNullOrEmpty(approverError) || titleErrors.length !== 0 || descriptionErrors.length !== 0) {
            console.log('Validation failed. Errors:', formErrors);
            return false;
        }

        return true;
    };

    // Register WorkPlan button
    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // Perform validation
        if (!formValidate()) {
            return;
        }

        // Call API to post data for workplan registration
        const postWorkPlan = async () => {
            setIsLoading(true);
            try {
                const response: AxiosResponse<any> = await postData(WORKPLAN_REG_URL, token, {
                    'userId': user?.user_id,
                    'workPlan': workPlan,
                    'workPlanDetail': workPlanDetail
                });

                const responseData: any = response.data.data;

                navigate(`/workPlan/detail/${responseData.workplan_id}`);
            } catch (error) {
                console.error('An error occurred while processing the request.', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                } else {
                    setError('An error occurred while processing the request. Please try again later.');
                }
            } finally {
                setIsLoading(false);
            }
        };
        postWorkPlan();
    };

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Workplan Registration</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 mt-3">
                                        <div className="info-sec">
                                            <div className="form-details">

                                                <ShowMessage message={error} type={"error"} clearMessage={() => setError(null)} />

                                                {
                                                    isSupervisor(user?.role_id ?? 0)
                                                        ? (
                                                            <>
                                                                <WorkPlanTargetSelElement
                                                                    workPlanTarget={workPlanTarget}
                                                                    handleWorkPlanTargetChange={handleWorkPlanTargetChange}
                                                                />
                                                                <WorkPlanElement
                                                                    workPlanTarget={workPlanTarget}
                                                                    formErrors={formErrors}
                                                                    formRefs={formRefs}
                                                                    workPlan={workPlan}
                                                                    promoterList={promoterList}
                                                                    approverList={approverList}
                                                                    handleWorkPlanChange={handleWorkPlanChange}
                                                                />
                                                                <WorkPlanDetailElement
                                                                    workPlanDetail={workPlanDetail}
                                                                    formErrors={formErrors}
                                                                    formRefs={formRefs}
                                                                    handleWorkPlanDetailChange={handleWorkPlanDetailChange}
                                                                />
                                                            </>
                                                        ) : (<></>)
                                                }
                                                {
                                                    !isSupervisor(user?.role_id ?? 0)
                                                        ? (
                                                            <>
                                                                <WorkPlanElement
                                                                    workPlanTarget={workPlanTarget}
                                                                    formErrors={formErrors}
                                                                    formRefs={formRefs}
                                                                    workPlan={workPlan}
                                                                    promoterList={promoterList}
                                                                    approverList={approverList}
                                                                    handleWorkPlanChange={handleWorkPlanChange}
                                                                />
                                                                <WorkPlanDetailElement
                                                                    workPlanDetail={workPlanDetail}
                                                                    formErrors={formErrors}
                                                                    formRefs={formRefs}
                                                                    handleWorkPlanDetailChange={handleWorkPlanDetailChange}
                                                                />
                                                            </>
                                                        ) : (<></>)
                                                }

                                            </div>
                                        </div>
                                        <div className="form-actions px-4 mb-3 ">
                                            <div className="row justify-content-end">
                                                <div className="col-md-4 col-sm-8 d-flex justify-content-between gap-3">
                                                    <button className='btn btn-tertiary' onClick={handleGoBack}>Discard Changes</button>
                                                    <button className='btn btn-primary' onClick={handleSubmit}>Register Workplan</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <Preloader isLoading={isLoading} />
        </>
    );
};
export default WorkPlanReg;


// Get Workplan Element For User Id
const WorkPlanTargetSelElement: React.FC<{
    workPlanTarget: WorkPlanTarget, handleWorkPlanTargetChange: (event: ChangeEvent<HTMLInputElement>) => void
}> = ({ workPlanTarget, handleWorkPlanTargetChange }) => {

    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="title">
                        <div className="icon-sec">
                            <i className="ri-information-fill mr-2"></i>
                        </div>
                        <h5>Create Workplan</h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className='forms row'>
                        <div className="col-md-6 col-sm-6 form-group">
                            <div className="form-check">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="myself"
                                    id="myself"
                                    value={WorkPlanTarget.MYSELF}
                                    checked={workPlanTarget === WorkPlanTarget.MYSELF}
                                    onChange={handleWorkPlanTargetChange}
                                />
                                <label className="form-check-label" htmlFor="myself">
                                    Myself
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="promoter"
                                    id="promoter"
                                    value={WorkPlanTarget.OTHERS}
                                    checked={workPlanTarget === WorkPlanTarget.OTHERS}
                                    onChange={handleWorkPlanTargetChange}
                                />
                                <label className="form-check-label" htmlFor="promoter">
                                    Promoter
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

// Get Workplan Element For User Id
const WorkPlanUserIdElement: React.FC<{
    workPlanTarget: WorkPlanTarget;
    formErrors: { user_id: string };
    formRefs: { user_id: React.RefObject<HTMLSelectElement> };
    workPlan: WorkPlanProps | undefined;
    promoterList: WorkPlanUserSelectProps[];
    handleWorkPlanChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}> = ({ workPlanTarget, formErrors, formRefs, workPlan, promoterList, handleWorkPlanChange }) => {

    const { user } = useAuth();

    return (
        <>
            {
                isSupervisor(user?.role_id ?? 0) && WorkPlanTarget.OTHERS === workPlanTarget ?
                    (
                        <>
                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Promoter</label>
                                <select
                                    className={`form-select ${formErrors.user_id ? 'is-invalid' : ''}`}
                                    name='user_id'
                                    title='Promoter'
                                    ref={formRefs.user_id}
                                    value={workPlan?.user_id}
                                    onChange={handleWorkPlanChange}
                                >
                                    <option value="" defaultChecked >Choose Promoter</option>
                                    {promoterList?.map(promoterMap => (
                                        <option key={promoterMap.user_id} value={promoterMap.user_id}>{promoterMap.user_id} | {promoterMap.user_name}</option>
                                    ))}
                                </select>
                                {formErrors.user_id && <div className="invalid-feedback">{formErrors.user_id}</div>}
                            </div>
                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Assigned By</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='assigned_by'
                                    placeholder='Enter Assignee Id'
                                    title='Assignee Id'
                                    defaultValue={workPlan?.assigned_by}
                                    readOnly
                                />
                            </div>
                        </>
                    ) : (
                        <div className="col-md-6 col-sm-6 form-group">
                            <label className="form-label">User ID</label>
                            <input
                                type="text"
                                className="form-control"
                                name='user_id'
                                placeholder='Enter User Id'
                                title='User Id'
                                defaultValue={workPlan?.user_id}
                                readOnly
                            />
                        </div>
                    )
            }
        </>
    );
};

// Get Workplan Element
const WorkPlanElement: React.FC<{
    workPlanTarget: WorkPlanTarget;
    formErrors: { user_id: string, approver_id: string };
    formRefs: { user_id: React.RefObject<HTMLSelectElement>, approver_id: React.RefObject<HTMLSelectElement> };
    workPlan: WorkPlanProps | undefined;
    promoterList: WorkPlanUserSelectProps[];
    approverList: WorkPlanUserSelectProps[];
    handleWorkPlanChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}> = ({ workPlanTarget, formErrors, formRefs, workPlan, promoterList, approverList, handleWorkPlanChange }) => {
    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="title">
                        <div className="icon-sec">
                            <i className="ri-information-fill mr-2"></i>
                        </div>
                        <h5>Primary information</h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className='forms row'>
                        <div className="forms row">

                            <WorkPlanUserIdElement
                                workPlanTarget={workPlanTarget}
                                formErrors={formErrors}
                                formRefs={formRefs}
                                workPlan={workPlan}
                                promoterList={promoterList}
                                handleWorkPlanChange={handleWorkPlanChange}
                            />

                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='start_date'
                                    placeholder='Enter Start Date'
                                    title='Start Date'
                                    defaultValue={workPlan?.start_date}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">End Date</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name='end_date'
                                    placeholder='Enter End Date'
                                    title='End Date'
                                    defaultValue={workPlan?.end_date}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6 col-sm-6 form-group">
                                <label className="form-label">Approver</label>
                                <select
                                    className={`form-select ${formErrors.approver_id ? 'is-invalid' : ''}`}
                                    name='approver_id'
                                    title='Approver'
                                    ref={formRefs.approver_id}
                                    value={workPlan?.approver_id}
                                    onChange={handleWorkPlanChange}
                                >
                                    <option value="" defaultChecked >Choose Approver</option>
                                    {approverList?.map(approverMap => (
                                        <option key={approverMap.user_id} value={approverMap.user_id}>{approverMap.user_id} | {approverMap.user_name}</option>
                                    ))}
                                </select>
                                {formErrors.approver_id && <div className="invalid-feedback">{formErrors.approver_id}</div>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Get Workplan Detail Element
const WorkPlanDetailElement: React.FC<{
    workPlanDetail: WorkPlanDetailProps[] | [];
    formErrors: { title: string[], description: string[] };
    formRefs: { title: React.RefObject<HTMLInputElement[]>, description: React.RefObject<HTMLTextAreaElement[]> };
    handleWorkPlanDetailChange: (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}> = ({ workPlanDetail, formErrors, formRefs, handleWorkPlanDetailChange }) => {
    return (
        <>
            {workPlanDetail?.map((workPlanDetailMap, index) => (
                <div key={index} className="card mb-3">
                    <div className="card-header">
                        <div className="title">
                            <div className="icon-sec">
                                <i className="ri-calendar-line"></i>
                            </div>
                            <h5>{workPlanDetailMap.date}</h5>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='forms row'>
                            <div className="forms row">
                                <div className="col-md-6 col-sm-6 form-group">
                                    <label className="form-label">Title</label>
                                    <input
                                        type="text"
                                        className={`form-control ${formErrors.title[index] ? 'is-invalid' : ''}`}
                                        name='title'
                                        placeholder='Enter Title'
                                        title='Title'
                                        ref={(element) => {
                                            if (element && formRefs.title.current) {
                                                formRefs.title.current[index] = element;
                                            }
                                        }}
                                        value={workPlanDetailMap.title}
                                        onChange={(e) => handleWorkPlanDetailChange(index, e)}
                                    />
                                    {formErrors.title[index] && <div className="invalid-feedback">{formErrors.title[index]}</div>}
                                </div>
                                <div className="col-md-6 col-sm-6 form-group">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        className={`form-control ${formErrors.description[index] ? 'is-invalid' : ''}`}
                                        name='description'
                                        placeholder='Enter Description'
                                        title='Description'
                                        ref={(element) => {
                                            if (element && formRefs.description.current) {
                                                formRefs.description.current[index] = element;
                                            }
                                        }}
                                        value={workPlanDetailMap.description}
                                        onChange={(e) => handleWorkPlanDetailChange(index, e)}
                                    />
                                    {formErrors.description[index] && <div className="invalid-feedback">{formErrors.description[index]}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
