import React from 'react'
import { CreateButtonProps } from '../types/types';
import { NavLink } from 'react-router-dom';

const CreateButton: React.FC<CreateButtonProps> = ({ buttonName, navPath }) => {
    return (
        <div className="content-fab-btn">
            <NavLink to={navPath} className="btn fixed-button createButton">
                <i className="ri-add-fill px-1"></i>{buttonName}
            </NavLink>
        </div>
    )
}

export default CreateButton;