
import React, { useEffect, useState, MouseEvent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchDataWithParams, postData } from '../services/ApiService';
import { WORKPLAN_ATTACHMENT_UPLOAD_URL, WORKPLAN_ATTACHMENT_URL } from '../util/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationProps, WorkPlanAttachmentProps } from '../types/types';
import { isUnauthorizedRequest } from '../util/utils';
import Preloader from '../components/Preloader';
import ImageComponent from '../components/ImageComponent';
import MapComponent from '../components/MapComponent';
import Modal from '../components/Modal';
import ImageUpload from '../components/ImageUpload';
import LocationDisplay from '../components/LocationDisplay';
import ShowMessage from '../components/ShowMessage';

const WorkPlanAttachment: React.FC = () => {
    const { user, token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const { workplan_id } = useParams<{ workplan_id: string }>(); // Accessing the workplan_id from route parameters
    const { workplan_detail_id } = useParams<{ workplan_detail_id: string }>(); // Accessing the workplan_detail_id from route parameters
    const [workPlanAttachment, setWorkPlanAttachment] = useState<WorkPlanAttachmentProps[]>([]);

    const [validationMessage, setValidationMessage] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [location, setLocation] = useState<LocationProps | null>(null);

    useEffect(() => {
        setIsLoading(true);
        const fetchWorkPlanAttachmentDetail = async () => {
            try {
                const response: AxiosResponse<any> = await fetchDataWithParams(WORKPLAN_ATTACHMENT_URL, token, {
                    workplan_id: workplan_id,
                    workplan_detail_id: workplan_detail_id
                });

                const responseData: any = response.data.data;
                setWorkPlanAttachment(responseData.workPlanAttachment);

            } catch (error) {
                console.error('Error fetching workplan details', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            } finally {
                setIsLoading(false);
                // Add event listener to listen for messages from the WebView
                window.addEventListener("message", handleMessage);
            }
        };

        fetchWorkPlanAttachmentDetail();
        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener("message", handleMessage);
        };
        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };

    // Open modal dialogue
    const openModal = () => {
        setLocation(null);
        setModalOpen(true);
        window.postMessage('checkCameraPermission', '*');
    };

    // Close modal dialogue
    const closeModal = () => {
        setModalOpen(false);
    };

    // Handle file change
    const handleFileChange = (selFile: File | null) => {
        setFile(selFile);
        console.log('Uploaded file:', selFile instanceof File ? selFile.name : 'Image');
    };

    // Fetch GeoLocation
    const fetchLocation = () => {
        setIsLoading(true);
        window.postMessage('getLocation', '*');
    };

    // Function to handle messages received from the WebView
    const handleMessage = (event: MessageEvent) => {
        if (event.data) {
            setIsLoading(false);
            // Parse location data received from the WebView
            const locationData: LocationProps = { latitude: event.data.latitude, longitude: event.data.longitude };
            setLocation(locationData);
        }
    };

    // Handle file upload
    const handleSaveChanges = async () => {
        if (!file) {
            setValidationMessage('Please upload an image');
            return;
        } else if (!location || !location.latitude || !location.longitude) {
            setValidationMessage('Please enter latitude and longitude');
            return;
        }

        setValidationMessage('');
        setIsLoading(true);
        setModalOpen(false);

        // Upload file to Laravel backend
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('user_id', user?.user_id ?? '0');
            formData.append('workplan_id', workplan_id ?? '0');
            formData.append('workplan_detail_id', workplan_detail_id ?? '0');
            formData.append('latitude', location?.latitude ?? "0");
            formData.append('longitude', location?.longitude ?? "0");

            try {
                const response: AxiosResponse<any> = await postData(WORKPLAN_ATTACHMENT_UPLOAD_URL, token, formData);

                const responseData: any = response.data.data;
                setWorkPlanAttachment(responseData.workPlanAttachment);

            } catch (error) {
                console.error('Error uploading workplan attachment', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Workplan Attachment Details</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row mb-5 mt-3">
                                    <ListComponent workPlanAttachment={workPlanAttachment} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-fab-btn">
                <button className="btn fixed-button" onClick={openModal}>
                    <i className="ri-add-fill px-1"></i>Add Attachment
                </button>
            </div>

            <Modal
                isOpen={modalOpen}
                header="Add Attachment"
                headerIcon="ri-attachment-line px-1"
                onClose={closeModal}
                onSaveChanges={handleSaveChanges}
            >
                <ShowMessage message={validationMessage} type='error' clearMessage={() => setValidationMessage('')} />
                <ImageUpload handleFileChange={handleFileChange} />
                <LocationDisplay location={location} onFetchLocation={fetchLocation} />
            </Modal>

            <Preloader isLoading={isLoading} />
        </>
    );
};
export default WorkPlanAttachment;

/**
 * List Component
 */
const ListComponent: React.FC<{ workPlanAttachment: WorkPlanAttachmentProps[] }> = ({ workPlanAttachment }) => {
    return (
        <>
            {workPlanAttachment.map(workPlanAttachmentMap => (
                <div key={workPlanAttachmentMap.workplan_attachment_id} className="col-lg-4 col-sm-6 list-containers">
                    <div className="list-grid">
                        <div className="users">
                            <div className="user-header">
                                <div className="avatar-sec flex-shrink-1">
                                    <span className="user-name">{workPlanAttachmentMap.workplan_attachment_id}</span>
                                </div>
                            </div>
                            <div className="user-body">
                                <ul className="user-info">
                                    <li>
                                        <ImageComponent
                                            imageName={workPlanAttachmentMap.image}
                                            imageType={workPlanAttachmentMap.imageType}
                                            imageData={workPlanAttachmentMap.imageData} />
                                    </li>
                                    <li>
                                        <MapComponent
                                            latitude={workPlanAttachmentMap.latitude}
                                            longitude={workPlanAttachmentMap.longitude} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
