
import React, { useEffect, useState, MouseEvent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData } from '../services/ApiService';
import { USER_DETAIL_URL } from '../util/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDetailProps } from '../types/types';
import { getRoleColor, isUnauthorizedRequest } from '../util/utils';
import Calendar from 'react-calendar';
import Preloader from '../components/Preloader';
import ImageComponent from '../components/ImageComponent';

import 'react-calendar/dist/Calendar.css';

const UserDetail: React.FC = () => {
    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { user_id } = useParams<{ user_id: string }>(); // Accessing the user_id from route parameters
    const [user, setUser] = useState<UserDetailProps>();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(USER_DETAIL_URL.replace('{user_id}', user_id ?? ''), token);

                const responseData: any = response.data.data;
                setUser(responseData.user);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching users', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchUser();
        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate(-1);
    };

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">User Details</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="content-body">
                                            <div className="user-info">
                                                <div className="profile-card">
                                                    <div className="profile-img">
                                                        <ImageComponent
                                                            imageName={user?.imageName!}
                                                            imageData={user?.imageData!}
                                                            imageType={user?.imageType!}
                                                            containerClassName="mb-0"
                                                            imageClassName="rounded-circle"
                                                        />
                                                        <div className="user-name">
                                                            <h4>{user?.name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="d-none d-sm-block" />
                                        <div className="info-sec">
                                            <div className="form-details">
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <h5>Contact</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item justify-content-between">
                                                            <div className="details-group">
                                                                <label className="details-label">Mobile</label>
                                                                <label className="details-value">{user?.mobile}</label>
                                                            </div>
                                                            <div className="icon-sec icon-call">
                                                                <a href={`tel:${user?.mobile}`}>
                                                                    <i className="ri-phone-line"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-details">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="widgets">
                                                            <div className="widget-group mb-4">
                                                                <div className="widget-label">Sales</div>
                                                                <div className="widget-value">Soon</div>
                                                            </div>
                                                            <div className="widget-group mb-4">
                                                                <div className="widget-label">Targets</div>
                                                                <div className="widget-value">Soon</div>
                                                            </div>
                                                            <div className="widget-group mb-4">
                                                                <div className="widget-label">Attendance</div>
                                                                <div className="widget-value">28 (Dec 2023)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-3">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <div className="d-block">
                                                    <Calendar />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-sec">
                                            <div className="form-details">
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-information-fill mr-2"></i>
                                                            </div>
                                                            <h5>Primary information</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-mail-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Email</label>
                                                                <label className="details-value">{user?.email}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-user-2-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Role</label>
                                                                <label className="details-value">
                                                                    <div className="user-designation">
                                                                        <span className={getRoleColor(user?.role ?? '')}>{user?.role}</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-map-pin-user-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Reporting to</label>
                                                                <label className="details-value">{user?.reportingUserName}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-shield-user-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Gender</label>
                                                                <label className="details-value">{user?.gender}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-folder-shield-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Activation Status</label>
                                                                <label className="details-value">{user?.status}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-map-pin-fill"></i>
                                                            </div>
                                                            <h5>Address Information</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item  no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">Address</label>
                                                                <label className="details-value">{user?.address}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item  no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">Pincode</label>
                                                                <label className="details-value">{user?.pincode}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item  no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">Landmark</label>
                                                                <label className="details-value">{user?.landmark}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec"><i className="ri-bank-fill"></i> </div>
                                                            <h5>Bank Information</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">Bank</label>
                                                                <label className="details-value">{user?.bank_name}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item  no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">Account No</label>
                                                                <label className="details-value">{user?.account_no}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item  no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">IFSC</label>
                                                                <label className="details-value">{user?.ifsc_code}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item  no-icon">
                                                            <div className="details-group">
                                                                <label className="details-label">Accountant Name</label>
                                                                <label className="details-value">{user?.accountant_name}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Preloader isLoading={isLoading} />
        </>
    );
};
export default UserDetail;
