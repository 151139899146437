import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData } from '../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { CLAIM_DETAILS_REG_URL, WORKPLAN_DETAILS_URL} from '../util/constants';
import { getAllowanceStatus, getAllowanceStatusColor, isUnauthorizedRequest } from '../util/utils';
import { ClaimProps, WorkProps,DashetailProps } from '../types/types';
import Preloader from './Preloader';
import ImagePopup from './ImagePopup';


import { DASHBOARD_DATA } from '../util/constants';

const Widgets:React.FC  = () => {


    

    const { user, token } = useAuth();
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
    const [workPlanWidgets, setWorkPlanWidgets] = useState<WorkProps[]>([]);
    const [claimWidgets, setClaimWidgets] = useState<ClaimProps[]>([]);
    const [dash, setDash] = useState<DashetailProps | undefined>();

     
    //  show image popup
     const hasImagePopupShownBefore = () => {
        return sessionStorage.getItem('imagePopupShown') === 'true';
    };

     //  show image popup
     const markImagePopupAsShown = () => {
        sessionStorage.setItem('imagePopupShown', 'true');
    };


     useEffect(() => {

        // get workplan  and claim Details
        const fetchDataForWidgets = async () => {
            try {

                

                const response: AxiosResponse<any> = await fetchData(DASHBOARD_DATA.replace('{user_id}', user?.user_id ?? ''), token);
                const workPlanResponse: AxiosResponse<any> = await fetchData(WORKPLAN_DETAILS_URL.replace('{user_id}', user?.user_id ?? ''), token);
                const claimResponse: AxiosResponse<any> = await fetchData(CLAIM_DETAILS_REG_URL.replace('{user_id}', user?.user_id ?? ''), token);

                const workPlanData: any = workPlanResponse.data.data;
                const claimData: any = claimResponse.data.data;
                const responseData: any = response.data;

                setWorkPlanWidgets(workPlanData?.workPlanDetail || []);
                setClaimWidgets(claimData?.claim || []);

                setDash(response.data);

                setIsLoading(false);

                 // If loading is false, show the ImagePopup
                 if (!hasImagePopupShownBefore()) {
                    setShowImagePopup(true);
                    markImagePopupAsShown();
                }
            
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                if (isUnauthorizedRequest(error)) {
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchDataForWidgets();
    },[user, token, navigate]);

   

    return (
        <div className="widgets">
        {/* Map over workPlan widgets */}
            <div className={`widget-group work-border`} >
                <div className="widget-top pos_rel">
                    <div className={`widget-details work-plan`}>
                        Today Work Plan
                    </div>
                    {workPlanWidgets?.length === 0 ? ( 
                          <div className="error-message error-datanotfound">Data not found</div>
                         ) : ( 
                           workPlanWidgets.map((widget, index) => (
                            <ul key={index} className='work-plan-lists'>
                                {widget?.title && <li className='widget-title'> <span>({index + 1})</span> <span className='mL5'>{widget?.title}</span></li>}
                                {/* {widget.description && <li className="widget-description">{widget.description}</li>} */}
                            </ul>
                      ))
                    )}
                </div>
            </div>
       
        {/* Map over claim widgets */}
            <div className={`widget-group claim-border`} >
                <div className="widget-top pos_rel">
                    <div className={`widget-details work-claim`}>
                        Claim
                    </div>
                    {claimWidgets?.length === 0 ? (
                <div className="error-message error-datanotfound">Data not found</div>
            ) : (
                claimWidgets.map((widget, index) => (
                    <ul key={index} className='work-plan-lists'>
                        {widget?.claim_type && <li> 
                            <div className='claim-status'>
                            <span className='widget-title'><span>({index + 1})</span> <span className='mL5'>{widget?.claim_type}</span></span>
                           <div className={`widget-status claim-status ${getAllowanceStatusColor(widget?.status)}`}>{getAllowanceStatus(widget?.status)}</div>
                            </div>
                            </li>
                            }
                    </ul>
                  ))
            )}
            </div>
        </div>
        <Preloader isLoading={isLoading} />
        {!isLoading && showImagePopup && <ImagePopup open={showImagePopup} close={setShowImagePopup} />}
    </div>
    
    );
};

export default Widgets;
