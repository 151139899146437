
import React, { useEffect, useState, MouseEvent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, postData } from '../services/ApiService';
import { WORKPLAN_DETAIL_URL, WORKPLAN_UPDATE_STATUS_URL } from '../util/constants';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { WorkPlanDetailProps, WorkPlanProps } from '../types/types';
import { getApprovalStatus, getApprovalStatusColor, getRoleColor, isDispCreateWorkPlanButton, isUnauthorizedRequest } from '../util/utils';
import { ApprovalStatus } from '../enums/enums';
import Preloader from '../components/Preloader';
import UpdateButton from '../components/UpdateButton';
import UpdateStatusButton from '../components/UpdateStatusButton';

const WorkPlanDetail: React.FC = () => {
    const { user, token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { workplan_id } = useParams<{ workplan_id: string }>(); // Accessing the workplan_id from route parameters
    const [workPlan, setWorkPlan] = useState<WorkPlanProps>();
    const [workPlanDetail, setWorkPlanDetail] = useState<WorkPlanDetailProps[]>([]);

    useEffect(() => {
        const fetchWorkPlanDetail = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(WORKPLAN_DETAIL_URL.replace('{workplan_id}', workplan_id ?? ''), token);

                const responseData: any = response.data.data;
                setWorkPlan(responseData.workPlan);
                setWorkPlanDetail(responseData.workPlanDetail);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching workplan details', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchWorkPlanDetail();
        // eslint-disable-next-line
    }, []);

    // Back button
    const handleGoBack = (event: MouseEvent<HTMLButtonElement>): void => {
        navigate('/workPlan/list');
    };

    // Update approval status
    const postApprovalStatus = async (approval_status: ApprovalStatus) => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await postData(WORKPLAN_UPDATE_STATUS_URL, token, {
                userId: user?.user_id,
                workplan_id: workplan_id,
                approval_status: approval_status
            });

            const responseData: any = response.data.data;
            setWorkPlan(responseData.workPlan);
            setWorkPlanDetail(responseData.workPlanDetail);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error updating workplan approval status', error);
            if (isUnauthorizedRequest(error)) {
                // Redirect to the login screen
                navigate('/login', { replace: true });
            }
        }
    };

    // Handle approve action
    const handleApprove = () => {
        postApprovalStatus(ApprovalStatus.Approved);
    };

    // Handle reject action
    const handleReject = () => {
        postApprovalStatus(ApprovalStatus.Rejected);
    };

    return (
        <>
            <div className="mobile-only ">
                <div className="content">
                    <div className="content-main">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="mobile-header fixed-top align-items-center py-2 px-3 border-bottom sub-header">
                                    <div className="back-list">
                                        <button onClick={handleGoBack} className="btn back-btn" data-toggle="tooltip" title="Back to List">
                                            <i className="ri-arrow-left-line"></i>
                                        </button>
                                        <span className="chat-name">Workplan Details</span>
                                    </div>
                                    <div className="user-action gap-4">
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-8 mt-3">
                                        <div className="info-sec">
                                            <div className="form-details">
                                                <div className="card mb-3">
                                                    <div className="card-header">
                                                        <div className="title">
                                                            <div className="icon-sec">
                                                                <i className="ri-information-fill mr-2"></i>
                                                            </div>
                                                            <h5>Primary information</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-user-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">User</label>
                                                                <label className="details-value">{workPlan?.user_name}&nbsp;({workPlan?.user_id})</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-calendar-schedule-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Period</label>
                                                                <label className="details-value">{workPlan?.start_date}~{workPlan?.end_date}</label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-pass-valid-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Approver Name</label>
                                                                <label className="details-value">{workPlan?.approver_name}&nbsp;<span className={getRoleColor(workPlan?.approver_role ?? '0')}>{workPlan?.approver_role ?? '0'}</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="details-item">
                                                            <div className="icon-sec">
                                                                <i className="ri-verified-badge-line"></i>
                                                            </div>
                                                            <div className="details-group">
                                                                <label className="details-label">Approval Status</label>
                                                                <label className="details-value"><span className={getApprovalStatusColor(workPlan?.approval_status ?? -1)}>{getApprovalStatus(workPlan?.approval_status ?? -1)}</span></label>
                                                            </div>
                                                        </div>
                                                        {workPlan?.assigned_by &&
                                                            <div className="details-item">
                                                                <div className="icon-sec">
                                                                    <i className="ri-user-shared-2-line"></i>
                                                                </div>
                                                                <div className="details-group">
                                                                    <label className="details-label">Assigned By</label>
                                                                    <label className="details-value">{workPlan?.assigned_by_name}</label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ListComponent workPlanDetail={workPlanDetail} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {workPlan?.approver_id === user?.user_id && <UpdateStatusButton handleApprove={handleApprove} handleReject={handleReject} />}

            {isDispCreateWorkPlanButton(user?.role_id ?? 0) && <UpdateButton buttonName='Edit Workplan' navPath={`/workPlan/update/${workplan_id}`} />}

            <Preloader isLoading={isLoading} />
        </>
    );
};
export default WorkPlanDetail;

/**
 * List Component
 */
const ListComponent: React.FC<{ workPlanDetail: WorkPlanDetailProps[] }> = ({ workPlanDetail }) => {
    return (
        <>
            {workPlanDetail.map(workPlanDetailMap => (
                <div key={workPlanDetailMap.workplan_detail_id} className="col-lg-4 col-sm-6 list-containers">
                    <div className="list-grid">
                        <div className="users">
                            <div className="user-header">
                                <div className="avatar-sec flex-shrink-1">
                                    <span className="user-name">{workPlanDetailMap.date}</span>
                                </div>
                            </div>
                            <div className="user-body">
                                <ul className="user-info">
                                    <li>
                                        <div className="icon-sec"><span><i className="ri-todo-line"></i></span></div>
                                        <div className="icon-detail"><span>{workPlanDetailMap.title}</span></div>
                                    </li>
                                    <li>
                                        <div className="icon-detail"><span>{workPlanDetailMap.description}</span></div>
                                    </li>
                                </ul>
                            </div>
                            <div className="grid-actions">
                                <NavLink to={`/workPlan/attachment/${workPlanDetailMap.workplan_id}/${workPlanDetailMap.workplan_detail_id}`} className="dt-link">View Attachment <i className="ri-arrow-right-s-line"></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
