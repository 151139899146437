import React from 'react';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

interface LogoutButtonProps {
    styleClassName?: string; // Define styleClassName as optional
    children?: React.ReactNode; // Define children as optional
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ styleClassName, children }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = async () => {
        const isLogoutSuccess: boolean = await logout();
        if (isLogoutSuccess) {
            // Navigate to login screen
            navigate('/login', { replace: true });
        }
    };

    return (
        <button className={styleClassName} onClick={handleLogout}>
            {children} {/* Render children */}
        </button>
    );
};

export default LogoutButton;
