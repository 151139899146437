import React from 'react';

interface PreloaderProps {
    isLoading: boolean;
}

const Preloader: React.FC<PreloaderProps> = ({ isLoading }) => {
    return (
        <>
            {isLoading && (
                <div id="preloader" className="bg-light-subtle">
                    <div className="preloader-wrap">
                        <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="logo" className="img-fluid preloader-icon" />
                        <div className="loading-bar"></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Preloader;
