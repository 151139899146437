
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchData, fetchDataWithParams } from '../services/ApiService';
import { CLAIM_LIST_URL } from '../util/constants';
import Preloader from '../components/Preloader';
import { formatMoney, getAllowanceStatus, getAllowanceStatusColor, getRoleColor, isDispCreateWorkPlanButton, isUnauthorizedRequest } from '../util/utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { ClaimListProps } from '../types/types';
import CreateButton from '../components/CreateButton';

const ClaimList: React.FC = () => {
    const { user, token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [claims, setClaims] = useState<ClaimListProps[]>([]);

    const [searchBy, setSearchBy] = useState<string>('');

    useEffect(() => {
        const fetchClaims = async () => {
            try {
                const response: AxiosResponse<any> = await fetchData(CLAIM_LIST_URL, token);

                const responseData: any = response.data.data;
                setClaims(responseData.claims);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching claims', error);
                if (isUnauthorizedRequest(error)) {
                    // Redirect to the login screen
                    navigate('/login', { replace: true });
                }
            }
        };

        fetchClaims();
        // eslint-disable-next-line
    }, []);

    // Search button click event
    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(CLAIM_LIST_URL, token, {
                searchBy: searchBy
            });

            const responseData: any = response.data.data;
            setClaims(responseData.claims);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching claims', error);
            if (isUnauthorizedRequest(error)) {
                // Redirect to the login screen
                navigate('/login', { replace: true });
            }
        }
    }

    return (
        <>
            <div className="content">
                <div className="content-main">
                    <div className="content-wrapper">
                        <div className="title-container border-bottom">
                            <div className="title">
                                <h3>Claim</h3>
                            </div>
                        </div>
                        <div className="searchFilterContainer">
                            <div className="input-group input-group-merge merge-right searchGroup">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchBy}
                                    onChange={(e) => setSearchBy(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary searchBtn"
                                    onClick={handleSearch}
                                >
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <button type="button" className="btn btn-filter btn-light" data-bs-toggle="offcanvas" data-bs-target="#filterby">
                                <i className="ri-filter-3-line"></i>
                            </button>
                        </div>
                        <div className="content-body">
                            <div className="container">
                                <div className="row">

                                    {claims.map(claim => (
                                        <div key={claim.claim_id} className="col-lg-4 col-sm-6 list-containers">
                                            <div className="list-grid">
                                                <div className="users">
                                                    <div className="user-header">
                                                        <div className="avatar-sec flex-shrink-1">
                                                            <span className="user-name" title={claim.claim_id}>{claim.claim_id}</span>
                                                        </div>
                                                        <div className="avatar-sec flex-shrink-1">
                                                            <span className="user-name" title={claim.user_id}>{claim.user_id}</span>
                                                        </div>
                                                        <div className="item-actions">
                                                            <div className="user-designation">
                                                                <span className={getAllowanceStatusColor(claim.status)}>{getAllowanceStatus(claim.status)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-body">
                                                        <ul className="user-info">
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-user-add-line"></i></span></div>
                                                                <div className="icon-detail"><span>{claim.applied_by} <span className={getRoleColor(claim.role)}>{claim.role}</span></span></div>
                                                            </li>
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-hand-coin-line"></i></span></div>
                                                                <div className="icon-detail"><span>{claim.claim_type}</span></div>
                                                            </li>
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-calendar-schedule-line"></i></span></div>
                                                                <div className="icon-detail"><span>{claim.applied_date}</span></div>
                                                            </li>
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-money-rupee-circle-line"></i></span></div>
                                                                <div className="icon-detail"><span>{formatMoney(claim.amount)}</span></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="grid-actions">
                                                        <NavLink to={`/claim/detail/${claim.claim_id}`} className="dt-link">View Details <i className="ri-arrow-right-s-line"></i></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {isDispCreateWorkPlanButton(user?.role_id ?? 0) && <CreateButton buttonName='Create Claim' navPath='/claim/reg' />}

            <Preloader isLoading={isLoading} />
        </>
    );
};

export default ClaimList;
