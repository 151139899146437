import React from 'react'
import { UpdateStatusButtonProps } from '../types/types';

const UpdateStatusButton: React.FC<UpdateStatusButtonProps> = ({ handleApprove, handleReject }) => {
    return (
        <>
            <div className="fixed-bottom">
                <div className="card bg-white shadow" style={{ width: '100vw' }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <button onClick={handleApprove} className="btn btn-success btn-block btn-lg shadow">
                                    <i className="ri-checkbox-circle-line me-1"></i>Approve
                                </button>
                            </div>
                            <div className="col">
                                <button onClick={handleReject} className="btn btn-danger btn-block btn-lg shadow">
                                    <i className="ri-close-circle-line me-1" />Reject
                                </button>
                            </div>
                        </div >
                    </div>
                </div>
            </div >
        </>
    )
}

export default UpdateStatusButton;