
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { AxiosResponse } from 'axios';
import { fetchDataWithParams } from '../services/ApiService';
import { ORDER_List_URL } from '../util/constants';
import { getFirstLetter, getOrderStatusColor, isUnauthorizedRequest } from '../util/utils';
import { NavLink, useNavigate } from 'react-router-dom';
import {  OrderListProps ,} from '../types/types';
import Preloader from '../components/Preloader';

const UserList: React.FC = () => {
    const { user, token } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [orders, setOrders] = useState<OrderListProps[]>([]);
  


    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line
    }, []);

    // Search button click event
    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const response: AxiosResponse<any> = await fetchDataWithParams(ORDER_List_URL, token, {
            
            });

            const responseData: any = response.data;
            setOrders(responseData.orders);
         

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching users', error);
            if (isUnauthorizedRequest(error)) {
                // Redirect to the login screen
                navigate('/login', { replace: true });
            }
        }
    }

    return (
        <>
            <div className="content">
                <div className="content-main">
                    <div className="content-wrapper">
                        <div className="title-container border-bottom">
                            <div className="title">
                                <h3>Orders List</h3>
                            </div>
                        </div>
                        {/* <div className="searchFilterContainer">
                            <div className="input-group input-group-merge merge-right searchGroup">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary searchBtn"
                                    onClick={handleSearch}
                                >
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <button type="button" className="btn btn-filter btn-light" data-bs-toggle="offcanvas" data-bs-target="#filterby">
                                <i className="ri-filter-3-line"></i>
                            </button>
                        </div> */}
                        <div className="content-body mb-5">
                            <div className="container">
                                <div className="row">

                                    {orders.map(userMap => (
                                        <div key={userMap.user_id} className="col-lg-4 col-sm-6 list-containers">
                                            <div className="list-grid">
                                                <div className="users">
                                                    <div className="user-header">
                                                        <div className="avatar-sec flex-shrink-1">
                                                            <span className="user-avatar-initial avatar">{getFirstLetter(userMap.name)}</span>
                                                            <span className="user-name" title={userMap.name}>OrderId:{userMap.order_id}</span>
                                                        </div>
                                                        <div className="item-actions">
                                                            <div className="user-designation">
                                                            {  userMap?.status === 'OPEN'  && (
                                                        <span className="badge text-bg-primary">OPEN</span>

                                                       )}
                                                         {  userMap?.status === 'DELIVERED'  && (
                                                        <span className="badge badge-color-3">DELIVERED</span>

                                                       )}
                                                         {  userMap?.status === 'APPROVED'  && (
                                                        <span className="badge badge-color-7">APPROVED</span>

                                                       )}
                                                         {  userMap?.status === 'REJECT'  && (
                                                        <span className="badge text-bg-danger">REJECT</span>

                                                       )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-body">
                                                        <ul className="user-info">
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-smartphone-line"></i></span></div>
                                                                <div className="icon-detail"> <span>Order Date:{userMap.created_at}</span></div>
                                                            </li>
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-mail-line"></i></span></div>
                                                                <div className="icon-detail"><span>{userMap.orderTotalAmount}</span></div>
                                                            </li>
                                                            <li>
                                                                <div className="icon-sec"><span><i className="ri-map-pin-line"></i></span></div>
                                                                <div className="icon-detail"><span>{userMap.city_name}</span></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="grid-actions">
                                                    <NavLink to={`/order/detail/${userMap.order_id}`} className="dt-link">View Details <i className="ri-arrow-right-s-line"></i></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="offcanvas offcanvas-bottom" tabIndex={-1} id="filterby" aria-labelledby="FilterLabel">
                <div className="dragger"></div>
                <div>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="FilterLabel">Filters</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>
                <div className="offcanvas-body">
                    <div className="filters">
                        <div className="form-select-group">
                            {/* <select
                                className="form-select"
                                aria-label="Role"
                                value={filterRole}
                                onChange={(e) => setFilterRole(e.target.value)}
                            >
                                <option value="">All Role</option>
                                {roles.map(role => (
                                    <option key={role.id} value={role.id}>{role.disp_name}</option>
                                ))}
                            </select> */}
                        </div>
                        <div className="form-select-group">
                            {/* <select
                                className="form-select"
                                aria-label="City"
                                value={filterCity}
                                onChange={(e) => setFilterCity(e.target.value)}
                            >
                                <option value="">All City</option>
                                {cities.map(city => (
                                    <option key={city.id} value={city.id}>{city.city_name}</option>
                                ))}
                            </select> */}
                        </div>
                        <div className="form-select-group">
                            {/* <select
                                className="form-select"
                                aria-label="Status"
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                            >
                                <option value="">All Status</option>
                                {statuses.map(status => (
                                    <option key={status.id} value={status.id}>{status.name}</option>
                                ))}
                            </select> */}
                        </div>
                        <div className="form-select-group">
                            <label className="d-flex">
                                Show Entries
                                {/* <select
                                    className="form-select"
                                    aria-label="Status"
                                    value={filterEntriesPerPage}
                                    onChange={(e) => setFilterEntriesPerPage(e.target.value)}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select> */}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-footer">
                    <div className="d-flex justify-content-between gap-4">
                        <button className="btn btn-tertiary btn-block" data-bs-dismiss="offcanvas">Cancel</button>
                        <button className="btn btn-primary btn-block" data-bs-dismiss="offcanvas" onClick={handleSearch}>Apply</button>
                    </div>
                </div>
            </div >

            <Preloader isLoading={isLoading} />
        </>
    );
};

export default UserList;
